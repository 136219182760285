var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationProvider',{staticStyle:{"max-width":"97%!important"},attrs:{"rules":_vm.rules,"name":_vm.validatorName,"disabled":_vm.disabled || _vm.disabledValidate},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{ref:"wrapper",staticClass:"form-group-float position-relative",style:({marginBottom: _vm.hideDetails ? '0px': '5px'})},[(_vm.label != '')?_c('div',{ref:"lbl",staticClass:"label position-absolute label_editor",staticStyle:{"top":"-7px","font-size":"10px","z-index":"10"},on:{"click":_vm.clickLabel}},[_c('label',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.label))])]):_vm._e(),_c('fieldset',{staticClass:"fs",class:[{
         disabled: _vm.disabled,
         'is-valid': !_vm.disabled ? (_vm.disabledValidate ? null : _vm._getValidationState(validationContext)) : null,
         'is-invalid': !_vm.disabled ? (_vm.disabledValidate ? null : _vm._getValidationState(validationContext) === false) : null
       },
       ((_vm.disabled || _vm.disabledValidate) ? '' : _vm.getClassIndicate(validationContext))
      ]},[_c('legend',{staticClass:"lg",style:({ width : _vm.widthlg})},[_vm._v(" ")]),(!_vm.actioneditor)?_c('vue-editor',{ref:"editoractionView",staticClass:"editorvueaction",attrs:{"id":"editoractionView","editor-toolbar":_vm.customActionToolbar,"disabled":_vm.disabled,"editorOptions":_vm.hideToolBar ? _vm.editorOptions :{},"placeholder":_vm.placeholder},on:{"focus":_vm.fieldFocus,"blur":_vm.fieldBlur},model:{value:(_vm.editorContent),callback:function ($$v) {_vm.editorContent=$$v},expression:"editorContent"}}):_vm._e(),(_vm.actioneditor)?_c('vue-editor',{ref:"editoractionAdd",staticClass:"editorvueaction",attrs:{"id":"editoractionAdd","editor-toolbar":_vm.customActionToolbar,"disabled":_vm.disabled,"editorOptions":_vm.hideToolBar ? _vm.editorOptions :{},"placeholder":_vm.placeholder},on:{"blur":_vm.fieldBlr},model:{value:(_vm.editorContent),callback:function ($$v) {_vm.editorContent=$$v},expression:"editorContent"}}):_vm._e()],1),(!_vm.hideDetails)?_c('b-form-invalid-feedback',{staticClass:"error"},[_vm._v(_vm._s(validationContext.errors[0]))]):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }