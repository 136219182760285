import moment from 'moment-timezone'
import { _findObj, toLocalDate, toLocal, actionCellRenderer, recallActionRenderCell, supplierRecallActionRenderCell, supplierLocationActionRenderCell, supplierDisruptionActionRenderCell, providerRecallActionRenderCell,ErResponseActions,supplierSalesRepActionRenderCell,distributionListsActionRenderCell,distributionListsNameRenderCell} from './genericFunctions'
import { NOTI_RECALL_STATUS, OUTPUT_DATE_FORMAT, OUTPUT_SHORTDATE_FORMAT, PROVIDER_COMMUNICATION_TYPE, RECALL_CLASSIFICATIONS, CLASSIFICATIONFILTER, ORGANIZATION_STATUS, PRODUCT_DISRUPTION_TYPE,ALLOCATIONPROCESS,ER_RECALL_STATUS,RECALL_STATUS, SOURCEFILTER,ACTION_TYPE,PROVIDER_RECALL_STATUS,PROVIDER_EVENTLISTING_STATUS,PROVIDERDISRUPTIOSTATUSFILTER, SALES_REP_STATUS, ANTICIPATED_DURATION, ANTICIPATEDDURATIONFILTER, ANTICIPATED_DURATION1} from '../misc/commons'
import orgServices from '../services/notisphere/organization'
export const Table_Header_Customization = [
    {
        userId: null,
        Notisphere_Login: {
            Recall_Headers: [],
            Recall_Filters: [],
            Supplier_Headers: [],
            Provider_Headers: [],
            OpenFDA_Recall_Headers: [],
            OpenFDA_Recall_Filters: [],
            OpenFDA_Drug_Recall_Headers: [],
            OpenFDA_Drug_Recall_Filters: [],
        },
        Supplier_Login: {
            Recall_Headers: [],
            Disruption_Headers: [],
            Disruption_Filters: [],
            Recall_Filters: [],
            Recall_AffectedLocation_Headers: [],
            Recall_AffectedCustomer_Headers: [],
            Recall_AffectedLocation_Filters: [],
            Recall_AffectedCustomer_Filters: [],
            SalesReps_Headers: [],
            SalesReps_Filters: [],
            Location_Headers: [],
            Location_Filters: [],
        },
        Provider_Login: {
            Recall_Headers: [],
            Recall_Filters: [],
            Disruption_Filters: [],
            Disruption_Headers: [],
            LocationVerification_Headers: [],
            LocationVerification_Filters: [],
            Er_Response_Headers: [],
            Er_Response_Filters: []
        },
    }]
export function trimWhiteSpaces(filter, value, filterText) {
    return true
}
export function txtFormatter(filter, value, filterText) {
    return true
}

export function SortingComparator(valueA, valueB, nodeA, nodeB, isDescending) {
    if(isDescending) {
        if(typeof InstallTrigger !== 'undefined'){
            return true
        }
        else{
            if (valueA == valueB) return 0
            return (valueA > valueB) ? 0 : -1
        }
    }
}
export function DateComparator(filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue

    if (dateAsString == null) {
        return 0
    }
    // In the example application, dates are stored as dd/mm/yyyy
    // We create a Date object for comparison against the filter date
    var dateSplit = dateAsString.split('T')
    var dateParts = dateSplit[0].split('-')
    var day = Number(dateParts[2])
    var month = Number(dateParts[1]) - 1
    var year = Number(dateParts[0])
    var cellDate = new Date(year, month, day)

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
        return -1
    } else if (cellDate > filterLocalDateAtMidnight) {
        return 1
    }
    return 0
}

export const CustomizedTableHeaders_Key = '1E890D44-A048-4A69-A951-455D739FF8D4'
export const FilterConnections_Key = '243A6021-47A0-4912-A101-C4AB08C053C8'
export const AG_GridCustomizedTableHeaders_Key = '39C7744F-41FD-4EEE-94CA-322331EB046A'
export const Notisphere_AgGrid_Callback_Functions = {
    providerDocumentDownload: null
}
export const Notisphere_Recalls_AgGrid = [
    {
        minWidth: 150, field: 'status_provider',type: 'centerColumn', filterParams: { values: ['Completed', 'Not Completed'] }, hide: false, headerName: 'Complete', sortable: true,filter: 'agSetColumnFilter', menuTabs: ['generalMenuTab','filterMenuTab', 'columnsMenuTab']
        , cellRenderer: (params) => { if (params?.value == 'COMPLETED') { return '<i class="fas fa-check text-success"></i>' } }
    },
    { minWidth: 150, field: 'creator_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Creator', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 150, field: 'communicationUID', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Recall ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 150, field: 'name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator },
    {
        minWidth: 170, field: 'classification', filterParams: { values: CLASSIFICATIONFILTER }, hide: false, headerName: 'FDA Classification', sortable: true, filter: 'agSetColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], cellRenderer: (params) => {
            var ob = document.createElement('div')
            ob.innerHTML = `<b-button  class="status-btn mx-auto"> <span > ${params.value} </span> </b-button>`
            return ob
        }
    },
    {
        minWidth: 120,
        field: 'status',
        type: 'centerColumn',
        filterParams: { values: ['NA','DRAFT','OPEN','CLOSED','BOUNCE','SENT','DELIVERY'],valueFormatter: params => {
            if (params.value == 'NA') return '(Blanks)'
            else if (params.value == 'DRAFT') return 'Draft'
            else if (params.value == 'OPEN') return 'Open'
            else if (params.value == 'CLOSED') return 'Closed'
            else if (params.value == 'BOUNCE') return 'Error'
            else if (params.value == 'SENT') return 'Sent'
            else if (params.value == 'DELIVERY') return 'Delivery'
            else return params.value
        }  },
        hide: false,
        headerName: 'Status',
        sortable: true,
        filter: 'agSetColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        cellRenderer: (params) => {
            if (params.value === 'DRAFT' || params.value === 'OPEN' || params.value === 'CLOSED' || params.value == 'ERROR' || params.value == 'SENT'|| params.value == 'DELIVERY' || params.value == 'BOUNCE') {
                var ob = document.createElement('div')
                ob.innerHTML = `<b-button  class="status-btn mx-auto">
                                    <span style="background: ${_findObj(NOTI_RECALL_STATUS, params.value, 'value', 'color')}; width: 100px;
                                    font-size: 0.75rem;;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                        ${_findObj(NOTI_RECALL_STATUS, params.value)} 
                                    </span> 
                                </b-button>`
                return ob
            }
        }
    },
    {
        minWidth: 120, field: 'communicationDate', hide: false, headerName: 'Recall Date', sortable: true, filter: 'agDateColumnFilter',

        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    {
        minWidth: 120, field: 'completeddate', hide: false, headerName: 'Completed Date', sortable: true, filter: 'agDateColumnFilter',

        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    { minWidth: 90, field: 'days_open',type: 'rightColumn', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: 'Days Open', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    {
        minWidth: 95, field: 'source',type: 'centerColumn', filter: 'agSetColumnFilter', filterParams: {
            values: SOURCEFILTER }, hide: false, headerName: 'Source', sortable: true, menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],  comparator: SortingComparator, cellRenderer: (params) => {
            let img = null
            if (params.value == 'Paper') {
                img = require('@/assets/img/icon-mail.svg')
            } else {
                img = require('@/assets/img/Notisphere_Icon_Crescent_Mono_Dark.png')
            }
            if (img !== null && img !== 'null' && img !== undefined && img !== '' && img !== ' ') {
                return `<img src=${img} width="16px" alt="" /> `
            }
        }
    },
    { minWidth: 110, field: 'number_of_providers',type: 'rightColumn', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: '# of Providers', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'sitecount', type: 'rightColumn', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: '# of Sites', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 110, field: 'isCustomizedContent', filterParams: { values: ['Yes','No','NA'] }, hide: true, headerName: 'Customized Content', sortable: true, filter: 'agSetColumnFilter', menuTabs: ['generalMenuTab','filterMenuTab','columnsMenuTab'] },
    { minWidth: 100, field: 'actions',type: 'centerColumn', hide: false, headerName: 'Actions', sortable: false, cellRenderer: recallActionRenderCell, suppressColumnsToolPanel: true },
]

export const Notisphere_Recalls_AffectedLocations_AgGrid = [
    { minWidth: 170, field: 'idn', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'IDN / Provider Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 150, field: 'location_id', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Location ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], suppressColumnsToolPanel: true },
    { minWidth: 150, field: 'location_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Location Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 150, field: 'address1', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Address 1', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 110, field: 'address2', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Address 2', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'city', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'City', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'state', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'State', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'zip_code', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Zip', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'status', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: 'Status', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
]
export const Supplier_Disruption_AffectedCustomers_AgGrid = [
    { minWidth: 150, field: 'account_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Account Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], suppressColumnsToolPanel: true },
    { minWidth: 150, field: 'email_address', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Email Address', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 110, field: 'first_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'First Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'last_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Last Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 150, field: 'account_number', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Account Number', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 170, field: 'idn', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'IDN', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 170, field: 'status', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces , filterOptions: ['equals']}, hide: false, headerName: 'Status', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
]

export const Supplier_Disruption_SalesReps_AgGrid = [
    { minWidth: 110, field: 'first_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'First Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'last_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Last Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 150, field: 'email_address', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Email', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],suppressColumnsToolPanel: true },
    { minWidth: 100, field: 'title', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Title', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'territory', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Territory', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'region', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Region', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 150, field: 'account_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Account Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 150, field: 'account_number', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Account ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 170, field: 'idn', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'IDN/Provider Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 170, field: 'status', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces , filterOptions: ['equals']}, hide: false, headerName: 'Status', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], cellRenderer: (params) => {
        var ob = document.createElement('div')
        ob.innerHTML = `<b-button  class="status-btn mx-auto">
                                <span style="background: ${_findObj(SALES_REP_STATUS, params.value, 'value', 'color')}; width: 100px;
                                font-size: 0.75rem;;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                    ${_findObj(SALES_REP_STATUS, params.value)} 
                                </span> 
                            </b-button>`
        return ob
    }
    },
]

export const Notisphere_Supplier_AgGrid = [
    { minWidth: 120, field: 'name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 120, field: 'add1', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Address 1', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 120, field: 'add2', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Address 2', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 90, field: 'city', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'City', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 100, field: 'state', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'State', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 80, field: 'zip', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Zip', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 80, field: 'sso', filterParams: { values: params => {
        orgServices.getSSOFilters().then(resp => {
            if (!resp.error) {
                params.success(resp.data.d)
            }
        })
    }}, hide: false, headerName: 'SSO', sortable: true,filter: 'agSetColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    {
        minWidth: 120, field: 'stt',type: 'centerColumn', filterParams: { values: params => {
            orgServices.getStatusFilters().then(resp => {
                if (!resp.error) {
                    params.success(resp.data.d)
                }
            })
        },valueFormatter: params => {
            return _findObj(ORGANIZATION_STATUS, params.value)
        } }, hide: false, headerName: 'Status', sortable: true, filter: 'agSetColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], cellRenderer: (params) => {
            var ob = document.createElement('div')
            ob.innerHTML = `<b-button  class="status-btn mx-auto">
                                <span style="background: ${_findObj(ORGANIZATION_STATUS, params.value, 'value', 'color')}; width: 98px;
                                font-size: 0.75rem;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                    ${_findObj(ORGANIZATION_STATUS, params.value)} 
                                </span> 
                            </b-button>`
            return ob
        }
    },
    { minWidth: 100, field: 'actions', type: 'centerColumn',hide: false, headerName: 'Actions', sortable: false, cellRenderer: actionCellRenderer, suppressColumnsToolPanel: true },
]
export const Notisphere_Provider_AgGrid = [
    { minWidth: 120, field: 'name', hide: false, headerName: 'Name', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'], comparator: SortingComparator},
    { minWidth: 150, field: 'notisphere_id', hide: false, headerName: 'Notisphere ID', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 120, field: 'add1', hide: false, headerName: 'Address 1', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 120, field: 'add2', hide: false, headerName: 'Address 2', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 90, field: 'city', hide: false, headerName: 'City', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 90, field: 'state', hide: false, headerName: 'State', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 80, field: 'zip', hide: false, headerName: 'Zip', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    {
        minWidth: 130,
        field: 'document',
        headerName: 'Agreement',
        hide: false,
        sortable: true,
        menuTabs: ['generalMenuTab', 'columnsMenuTab'],
        cellRenderer: (params) => {
            var docFrag = document.createDocumentFragment()
            for (let index = 0; index < params.value?.length; index++) {
                let itemdoc = params.value[index]
                let img = null
                var ob = document.createElement('div')
                if (itemdoc.mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || itemdoc.extension === 'docx' || itemdoc.extension === 'doc') {
                    img = require('@/assets/img/word.svg')
                } else if (itemdoc.mimetype == 'application/pdf' || itemdoc.extension == 'pdf') {
                    img = require('@/assets/img/adobe.svg')
                }
                if (img !== null && img !== 'null' && img !== undefined && img !== '' && img !== ' ') {
                    ob.innerHTML = `<button type="button" class="btn btn-sm font-20 font-weight-bold text-primary">
                                    <img src=${img} height="30" alt=""/> 
                                </button>`
                    ob.addEventListener('click', (event) => {
                        if (Notisphere_AgGrid_Callback_Functions.providerDocumentDownload != null && Notisphere_AgGrid_Callback_Functions.providerDocumentDownload != undefined) {
                            Notisphere_AgGrid_Callback_Functions.providerDocumentDownload(itemdoc)
                        }
                    })
                    docFrag.appendChild(ob)
                }
            }
            return docFrag
        }
    },
    {
        minWidth: 130, field: 'stt', hide: false, headerName: 'Status', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab']
        , cellRenderer: (params) => {
            var ob = document.createElement('div')
            ob.innerHTML = `<b-button  class="status-btn mx-auto">
                                <span style="background: ${_findObj(ORGANIZATION_STATUS, params.value, 'value', 'color')}; width: 98px;
                                font-size: 0.75rem;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                    ${_findObj(ORGANIZATION_STATUS, params.value)} 
                                </span> 
                            </b-button>`
            return ob
        }
    },
    { minWidth: 80, field: 'sites', hide: false, headerName: 'Sites', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'] },
    {
        minWidth: 100, field: 'actions', hide: false, headerName: 'Actions', suppressSorting: true, cellRenderer: actionCellRenderer, suppressColumnsToolPanel: true
    },
]

export const Supplier_Recalls_AgGrid = [
    { minWidth: 150, field: 'communicationUID', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Recall ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator},
    { minWidth: 150, field: 'name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator},
    { minWidth: 120, field: 'type', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Type', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    {
        minWidth: 140, field: 'classification', filter: 'agSetColumnFilter', filterParams: {
            values: CLASSIFICATIONFILTER }, hide: false, headerName: 'FDA Classification', sortable: true, menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab']

    },
    {
        minWidth: 100,
        field: 'status',
        type: 'centerColumn',
        filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] },
        hide: false,
        headerName: 'Status',
        sortable: true,
        filter: 'agTextColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        cellRenderer: (params) => {
            if (params.value === 'DRAFT' || params.value === 'OPEN' || params.value === 'CLOSED' || params.value == 'ERROR' || params.value == 'SENT') {
                var ob = document.createElement('div')
                ob.innerHTML = `<b-button  class="status-btn mx-auto">
                                    <span style="background: ${_findObj(NOTI_RECALL_STATUS, params.value, 'value', 'color')}; width: 70px;
                                    font-size: 0.75rem;;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                        ${_findObj(NOTI_RECALL_STATUS, params.value)} 
                                    </span> 
                                </b-button>`
                return ob
            }
        }
    },
    {
        minWidth: 100, field: 'communicationDate', hide: false, headerName: 'Recall Date', sortable: true, filter: 'agDateColumnFilter',

        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    { minWidth: 60, field: 'days_open', type: 'rightColumn',filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: 'Days Open', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 60, field: 'number_of_providers', type: 'rightColumn',filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: '# of Providers', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'actions', type: 'centerColumn',hide: false, headerName: 'Actions', suppressMovable: true, sortable: false, cellRenderer: supplierRecallActionRenderCell, suppressColumnsToolPanel: true }
]
export const Provider_Recalls_AgGrid = [
    {
        minWidth: 100, field: 'status_provider', type: 'centerColumn', filterParams: {
            values: ['Completed', 'Not Completed']
        }, hide: false, headerName: 'Complete', sortable: true, filter: 'agSetColumnFilter', menuTabs: ['generalMenuTab','filterMenuTab','columnsMenuTab']
        , cellRenderer: (params) => { if (params?.value == 'COMPLETED') { return '<i class="fas fa-check text-success"></i>' } }
    },
    { minWidth: 150, field: 'supplier_org_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Supplier', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 150, field: 'distributorName', filterParams: { filterOptions: ['contains', 'blank', 'notBlank'], }, hide: false, headerName: 'Distributor', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator },
    { minWidth: 150, field: 'communicationUID', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Recall ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 150, field: 'communicationName', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator },
    { minWidth: 120, field: 'categories', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Categories', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab']},
    { minWidth: 150, field: 'recall_sites', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: '# of Sites', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] , comparator: SortingComparator ,
        cellRenderer: (params) => {
            if(params.data.recallSiteCount > 1)
            {
                return params.data.recallSiteCount + ' affected sites'
            }
            if(params.data.recallSiteCount == 1)
            {
                return params.data.recallSiteName
            }
            if(params.data.recallSiteCount == 0)
            {
                return params.data.recall_sites
            }
        }

    },

    {
        minWidth: 130,
        field: 'allresponse_doc',
        type: 'centerColumn',
        headerName: 'Response Documents',
        hide: false,
        sortable: false,
        menuTabs: ['generalMenuTab', 'columnsMenuTab']
        ,
        cellRenderer: (params) => {
            var docFrag = document.createDocumentFragment()
            if(params.data.allresponse_doc.length > 0)
            {
                for (let index = 0; index < params.data.allresponse_doc?.length; index++) {
                    let itemdoc = params.data.allresponse_doc[index]
                    let img = null
                    let viewclickDom = null
                    var ob = document.createElement('div')

                    if (itemdoc.type == 'text/plain'  || itemdoc.extension == 'txt'  || itemdoc.extension == 'plain') {
                        img = require('@/assets/img/text-format.png')
                    }
                    else if(itemdoc.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || itemdoc.type == 'text/csv' || itemdoc.extension == 'xls' | itemdoc.extension == 'xlsx' ||  itemdoc.extension == 'csv'){
                        img = require('@/assets/img/excel.svg')
                    }
                    else if(itemdoc.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || itemdoc.extension == 'docx' || itemdoc.extension == 'doc'){
                        img = require('@/assets/img/word.svg')
                    }
                    else if(itemdoc.type == 'application/pdf' || itemdoc.extension == 'pdf'){
                        img = require('@/assets/img/adobe.svg')
                    }
                    else if (itemdoc.type == 'video/mp4' || itemdoc.type == 'image/png' || itemdoc.type == 'image/jpeg' || itemdoc.extension == '.png' || itemdoc.extension == 'jpg' || itemdoc.extension == 'svg'  || itemdoc.extension == 'jpeg' || itemdoc.extension == 'gif') {
                        img = require('@/assets/img/image.png')
                    }
                    else if (itemdoc.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || itemdoc.extension == 'ppt'|| itemdoc.extension == 'pptx') {
                        img = require('@/assets/img/powerpoint.svg')
                    }
                    else {
                        img = require('@/assets/img/default_icon.png')
                    }
                    if (img !== null && img !== 'null' &&img!== undefined && img !=='' && img !== ' ') {
                        viewclickDom = 'viewclick_' + itemdoc.id
                        ob.innerHTML = `<img class="m-1" src=${img}  data-action=${viewclickDom} height="30" alt=""/>`
                        docFrag.appendChild(ob)
                    }
                }
            }
            return docFrag
        }
    },
    {
        minWidth: 150,
        field: 'affectedProduct',
        filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces,filterOptions: ['contains', 'blank', 'notBlank'], },
        hide: false,
        headerName: 'Affected Product',
        sortable: true,
        filter: 'agTextColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        comparator: SortingComparator,
        cellRenderer: (params) => {
            if (params.data.isProviderCreated && params.data.recallSiteCount == 1) {
                return params.data.affectedProduct
            }
        }
    },
    {
        minWidth: 95, field: 'source', type: 'centerColumn', filter: 'agSetColumnFilter',  filterParams: {
            values: SOURCEFILTER }, hide: false, headerName: 'Source', sortable: true, menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],  comparator: SortingComparator, cellRenderer: (params) => {
            let img = null
            if (params.value == 'Paper') {
                img = require('@/assets/img/icon-mail.svg')
            } else {
                img = require('@/assets/img/Notisphere_Icon_Crescent_Mono_Dark.png')
            }
            if (img !== null && img !== 'null' && img !== undefined && img !== '' && img !== ' ') {
                return `<img src=${img} width="16px" alt="" /> `
            }
        }
    },
    { minWidth: 100, field: 'date_since_notified',type: 'rightColumn', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'contains']}, hide: false, headerName: 'Days Since Notified', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    {
        minWidth: 100,
        field: 'status',
        type: 'centerColumn',
        filterParams: {  textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces },
        hide: false,
        headerName: 'Supplier Event Status',
        sortable: true,
        filter: 'agTextColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        comparator: SortingComparator,
        cellRenderer: (params) => {
            if (!params.data.isProviderCreated) {
                var ob = document.createElement('div')
                ob.innerHTML = `<b-button  class="status-btn mx-auto pl-3"> <span > ${_findObj(PROVIDER_RECALL_STATUS, params.value)} </span> </b-button>`
                return ob
            }
        }
    },
    {
        minWidth: 120,
        field: 'eventstatus',
        type: 'centerColumn',
        filterParams: { values: PROVIDERDISRUPTIOSTATUSFILTER },
        hide: false,
        headerName: 'Provider Event Status',
        sortable: true,
        filter: 'agSetColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        comparator: SortingComparator,
        cellRenderer: (params) => {
            if (params.data.isProviderCreated && params.value != '' && params.value != null && params.value != undefined) {
                var ob = document.createElement('div')
                ob.innerHTML = `<b-button  class="status-btn mx-auto">
                <span style="background: ${_findObj(PROVIDER_EVENTLISTING_STATUS, params.value, 'value', 'color')}; width: 98px;
                font-size: 0.75rem;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                    ${_findObj(PROVIDER_EVENTLISTING_STATUS, params.value)} 
                </span> 
            </b-button>`
                return ob
            }
        }
    },
    {
        minWidth: 120,
        field: 'responseListingStatus', type: 'centerColumn',filterParams: {   textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces,filterOptions: ['contains', 'blank', 'notBlank'], },hide: false, headerName: 'Response Status', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator
        , cellRenderer: (params) => {
            if(params.data.isProviderCreated && params.value != ACTION_TYPE.DELETED && params.value != ACTION_TYPE.NA && params.value != ''){
                var ob = document.createElement('div')
                ob.innerHTML = `<b-button  class="status-btn mx-auto">
                            <span style="background: ${_findObj(RECALL_STATUS, params.value, 'value', 'color')}; width: 98px;
                            font-size: 0.75rem;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                ${_findObj(RECALL_STATUS, params.value)} 
                            </span> 
                        </b-button>`
                return ob
            }
        }
    },
    {
        minWidth: 120, field: 'communicationDate', hide: true, headerName: 'Event Date', sortable: true, filter: 'agDateColumnFilter',

        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    { minWidth: 130, field: 'type', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: true, headerName: 'Type', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], cellRenderer: (params) => {
        return _findObj(PROVIDER_COMMUNICATION_TYPE, params.value) } },
    { minWidth: 110, field: 'classification', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: true, headerName: 'FDA Classification', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], cellRenderer: (params) => {
        if (params.value === 'CLASSI' || params.value === 'CLASSII' || params.value === 'CLASSIII' || params.value === 'NOT_YET_CLASSIFIED') {
            var ob = document.createElement('div')
            ob.innerHTML = `<b-button  class="status-btn mx-auto"> <span > ${_findObj(RECALL_CLASSIFICATIONS, params.value)} </span> </b-button>`
            return ob
        } }
    },
    {
        minWidth: 120, field: 'responded_at', hide: true, headerName: 'Response Date', sortable: true, filter: 'agDateColumnFilter',

        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            //comparator: FilterDateComparator
        }
        , cellRenderer: (params) => {
            if (params?.data.status_provider == 'COMPLETED') {
                if (params.value == null || params.value == undefined || params.value == '' || params.value == '1900-01-01T00:00:00')
                    return null
                return toLocal(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
            }
        }
    },
    { minWidth: 100, field: 'actions', type: 'centerColumn',hide: false, headerName: 'Actions', sortable: false, cellRenderer: providerRecallActionRenderCell, suppressColumnsToolPanel: true },
]
export const Provider_Disruption_AgGrid = [
    {
        minWidth: 100, field: 'status_provider', type: 'centerColumn', filterParams: {
            values: ['Completed', 'Not Completed']
        }, hide: false, headerName: 'Complete', sortable: true, filter: 'agSetColumnFilter', menuTabs: ['generalMenuTab','filterMenuTab','columnsMenuTab']
        , cellRenderer: (params) => { if (params?.value == 'COMPLETED') { return '<i class="fas fa-check text-success"></i>' }}
    },
    { minWidth: 150, field:
         'supplier_org_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Supplier', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 150, field: 'communicationUID', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Advisory ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 150, field: 'communicationName', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator },
    {
        minWidth: 100,
        field: 'anticipatedDuration',
        headerName: 'Anticipated Duration',
        sortable: true,
        filter: 'agSetColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        comparator: SortingComparator,
        hide: false,
        filterParams: {
            values: ANTICIPATEDDURATIONFILTER.map(option => option.value),
            textFormatter: optionValue => {
                const found = ANTICIPATEDDURATIONFILTER.find(option => option.value === optionValue)
                return found ? found.display : optionValue
            },
            valueFormatter: params => {
                const found = ANTICIPATEDDURATIONFILTER.find(option => option.value === params.value)
                return found ? found.display : params.value
            }
        }
    },
    { minWidth: 120, field: 'type', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return _findObj( PRODUCT_DISRUPTION_TYPE, params.value )// toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        },
        hide: false, headerName: 'Type', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 120, field: 'categories', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Category', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] , comparator: SortingComparator },
    { minWidth: 150, field: 'recall_sites', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: '# of Sites', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] , comparator: SortingComparator ,
        cellRenderer: (params) => {
            if(params.data.recallSiteCount > 1)
            {
                return params.data.recallSiteCount + ' affected sites'
            }
            if(params.data.recallSiteCount == 1)
            {
                return params.data.recallSiteName
            }
            if(params.data.recallSiteCount == 0)
            {
                return params.data.recall_sites
            }
        }
    },
    {
        minWidth: 100, field: 'communicationDate', hide: false, headerName: 'Advisory Date', sortable: true, filter: 'agDateColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    {
        minWidth: 95, field: 'source', type: 'centerColumn', filter: 'agSetColumnFilter',  filterParams: {
            values: SOURCEFILTER }, hide: false, headerName: 'Source', sortable: true, menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],  comparator: SortingComparator, cellRenderer: (params) => {
            let img = null
            if (params.value == 'Paper') {
                img = require('@/assets/img/icon-mail.svg')
            } else {
                img = require('@/assets/img/Notisphere_Icon_Crescent_Mono_Dark.png')
            }
            if (img !== null && img !== 'null' && img !== undefined && img !== '' && img !== ' ') {
                return `<img src=${img} width="16px" alt="" /> `
            }
        }
    },
    { minWidth: 100, field: 'date_since_notified', type: 'rightColumn',filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'contains']}, hide: false, headerName: 'Days Since Notified', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    {
        minWidth: 120,
        field: 'eventstatus',type: 'centerColumn',filterParams: { values: PROVIDERDISRUPTIOSTATUSFILTER }
        ,hide: false, headerName: 'Status', sortable: true, filter: 'agSetColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator
        , cellRenderer: (params) => {
            if(params.value != ACTION_TYPE.DELETED && params.value != ACTION_TYPE.NA && params.value != '' && params.value != null){
                var ob = document.createElement('div')
                ob.innerHTML = `<b-button  class="status-btn mx-auto">
                            <span style="background: ${_findObj(PROVIDER_EVENTLISTING_STATUS, params.value, 'value', 'color')}; width: 98px;
                            font-size: 0.75rem;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                ${_findObj(PROVIDER_EVENTLISTING_STATUS, params.value)} 
                            </span> 
                        </b-button>`
                return ob
            }
        }
    },
    { minWidth: 100, field: 'actions', type: 'centerColumn',hide: false, headerName: 'Actions', sortable: false, cellRenderer: providerRecallActionRenderCell, suppressColumnsToolPanel: true },
]
export const Supplier_Disruptions_AgGrid = [

    { minWidth: 150, field: 'name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator},
    { minWidth: 120, field: 'type', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return _findObj( PRODUCT_DISRUPTION_TYPE, params.value )// toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        },
        hide: false, headerName: 'Type', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    {
        minWidth: 100,
        field: 'status',
        type: 'centerColumn',
        filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] },
        hide: false,
        headerName: 'Status',
        sortable: true,
        filter: 'agTextColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        cellRenderer: (params) => {
            if (params.value === 'DRAFT' || params.value === 'OPEN' || params.value === 'CLOSED' || params.value == 'ERROR' || params.value == 'SENT') {
                var ob = document.createElement('div')
                ob.innerHTML = `<b-button  class="status-btn mx-auto">
                                    <span style="background: ${_findObj(NOTI_RECALL_STATUS, params.value, 'value', 'color')}; width: 70px;
                                    font-size: 0.75rem;;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                        ${_findObj(NOTI_RECALL_STATUS, params.value)} 
                                    </span> 
                                </b-button>`
                return ob
            }
        }
    },
    { minWidth: 150, field: 'communicationUID', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Supply Advisory ID', filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    {
        minWidth: 100, field: 'communicationDate', hide: false, headerName: 'Advisory Date', sortable: true, filter: 'agDateColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    {
        minWidth: 100,
        field: 'anticipatedDuration',
        headerName: 'Anticipated Duration',
        sortable: true,
        filter: 'agSetColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        comparator: SortingComparator,
        hide: false,
        filterParams: {
            values: ANTICIPATEDDURATIONFILTER.map(option => option.value),
            textFormatter: optionValue => {
                const found = ANTICIPATEDDURATIONFILTER.find(option => option.value === optionValue)
                return found ? found.display : optionValue
            },
            valueFormatter: params => {
                const found = ANTICIPATEDDURATIONFILTER.find(option => option.value === params.value)
                return found ? found.display : params.value
            }
        }
    },
    { minWidth: 120, field: 'allocationprocess', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces },
        cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return _findObj( ALLOCATIONPROCESS, params.value )// toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        },
        hide: true, headerName: 'Allocation Process', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },

    { minWidth: 120, field: 'categories', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: true, headerName: 'Category', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] , comparator: SortingComparator },
    {
        minWidth: 100, field: 'notificationdate', hide: true, headerName: 'Notification Date', sortable: true, filter: 'agDateColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocal(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },

    {
        minWidth: 100, field: 'lastupdate', hide: true, headerName: 'Last Update', sortable: true, filter: 'agDateColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocal(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    {
        minWidth: 100, field: 'nextexpectedupdate', hide: true, headerName: 'Next Expected Update', sortable: true, filter: 'agDateColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return ''
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    {
        minWidth: 100, field: 'nexttaskreminder', hide: false, headerName: 'Next Task Reminder', sortable: true, filter: 'agDateColumnFilter',
        menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
            filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        }
        , cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
        }
    },
    // { minWidth: 120, field: 'createdby', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: true, headerName: 'Created By', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
    { minWidth: 100, field: 'actions',type: 'centerColumn', hide: false, headerName: 'Actions', sortable: false, cellRenderer: supplierDisruptionActionRenderCell, suppressColumnsToolPanel: true }
]
export const Supplier_Location_Headers = [
    { minWidth: 165, field: 'idn', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'IDN/Provider Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        comparator: SortingComparator
    },
    { minWidth: 120, field: 'location_id', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Location Id', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 150, field: 'location_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Location Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 110, field: 'address1', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Address 1', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 110, field: 'address2', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Address 2', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 100, field: 'city', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'City', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 100, field: 'state', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'State', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 100, field: 'zip_code', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Zip Code', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 145, field: 'notisphere_id', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Notisphere ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator },
    {
        minWidth: 160, field: 'last_change_at', hide: false, headerName: 'Last Update', sortable: true, filter: 'agDateColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
        headerClass: 'dt-header',
        filterParams: {
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            filterOptions: ['equals','empty'],
            defaultOption: 'equals',
            // provide comparator function
            comparator: DateComparator
        },
        cellRenderer: (params) => {
            if (params.value == null || params.value == undefined || params.value == '')
                return null
            return toLocal(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_SHORTDATE_FORMAT })
        }
    },
    {
        minWidth: 80, field: 'status', type: 'centerColumn',filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: 'Status', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], cellRenderer: (params) => {
            let img = null
            if (params.value === 'Covered') {
                img = require('@/assets/img/Notisphere_Icon_Crescent_Mono_Dark.svg')
            } else if (params.value === 'NotCovered') {
                img = require('@/assets/img/icon-mail.svg')
            }
            else if (params.value === 'Invalid') {
                img = require('@/assets/img/close-icon.svg')
            }
            else if (params.value === 'Emailed') {
                img = require('@/assets/img/at-svg.svg')
            }
            if (img !== null && img !== 'null' && img !== undefined && img !== '' && img !== ' ') {
                return `<img src=${img} width="16px" alt="" /> `
            }
        }
    },
    { minWidth: 100, field: 'actions', type: 'centerColumn',hide: false, headerName: 'Actions', sortable: false, cellRenderer: supplierLocationActionRenderCell, suppressMovable: true, suppressColumnsToolPanel: true },

]
export const Supplier_Sales_Representative_Headers = [
    { minWidth: 165, field: 'first_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'First Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 120, field: 'last_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Last Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 150, field: 'email_address', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Email Address', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 110, field: 'title', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Title', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 110, field: 'territory', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Territory', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 100, field: 'region', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Region', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 100, field: 'account_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Account Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 100, field: 'account_number', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Account Number', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
    { minWidth: 145, field: 'idn', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'IDN/Provider Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator },
    { minWidth: 80, field: 'status', type: 'centerColumn', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] }, hide: false, headerName: 'Status', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator },
    { minWidth: 100, field: 'actions',type: 'centerColumn', hide: false, headerName: 'Actions', sortable: false, cellRenderer: supplierSalesRepActionRenderCell, suppressMovable: true, suppressColumnsToolPanel: true },
]
export const Er_Response_AgGrid =
[{ minWidth: 135, field: 'response_status' , type: 'centerColumn',filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces, filterOptions: ['equals'] },hide: false, headerName: 'Status', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab']
    , cellRenderer: (params) => {
        var ob = document.createElement('div')
        ob.innerHTML = `<b-button  class="status-btn mx-auto">
                        <span style="background: ${_findObj(ER_RECALL_STATUS, params.value, 'value', 'color')}; width: 98px;
                        font-size: 0.75rem;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                            ${_findObj(ER_RECALL_STATUS, params.value)} 
                        </span> 
                    </b-button>`
        return ob
    }
},
{ minWidth: 150, field: 'supplier_name', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Supplier', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
{ minWidth: 150, field: 'distributorName', filterParams: { filterOptions: ['contains', 'blank', 'notBlank'], }, hide: false, headerName: 'Distributor', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],comparator: SortingComparator },
{ minWidth: 110, field: 'recallid', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Recall ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
{ minWidth: 100, field: 'recallname', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
{ minWidth: 110, field: 'notificationfiles',type: 'centerColumn', hide: false, headerName: 'Notification & Other Documents', sortable: false, menuTabs: ['generalMenuTab', 'columnsMenuTab'],
    cellRenderer: (params) => {
        var docFrag = document.createDocumentFragment()
        for (let index = 0; index < params.value?.length; index++) {
            let itemdoc = params.value[index]
            let img = null
            let viewclickDom = null
            var ob = document.createElement('div')
            if (itemdoc.mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || itemdoc.extension === 'docx' || itemdoc.extension === 'doc') {
                img = require('@/assets/img/word.svg')
            } else if (itemdoc.mimetype == 'application/pdf' || itemdoc.extension == 'pdf') {
                img = require('@/assets/img/adobe.svg')
            }
            else if (itemdoc.type == 'image/png' || itemdoc.type == 'image/jpeg' || itemdoc.extension == '.png' || itemdoc.extension == '.jpg' || itemdoc.extension == '.svg'  || itemdoc.extension == '.jpeg' || itemdoc.extension == '.gif') {
                img = require('@/assets/img/image.png')
            }
            else if(itemdoc.type == 'video/mp4' || itemdoc.type == 'audio/mp3')
            {
                img = require('@/assets/img/video-icon.jpg')
            }
            else if(itemdoc.mimetype == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || itemdoc.extension == 'ppt'|| itemdoc.extension == 'pptx')
            {
                img = require('@/assets/img/powerpoint.svg')
            }
            else if(itemdoc.mimetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || itemdoc.mimetype == 'text/csv' || itemdoc.extension == 'xls' | itemdoc.extension == 'xlsx' ||  itemdoc.extension == 'csv')
            {
                img = require('@/assets/img/excel.svg')
            }
            else if(itemdoc.mimetype == 'text/plain'  || itemdoc.extension == 'txt'  || itemdoc.extension == 'plain')
            {
                img = require('@/assets/img/text-format.png')
            }
            if (img !== null && img !== 'null' && img !== undefined && img !== '' && img !== ' ') {
                viewclickDom = 'viewclick_' + itemdoc.respondernotificationdocId
                ob.innerHTML = `<img class="m-1" src=${img} height="30" alt="" data-action=${viewclickDom}>
        </img>`
                docFrag.appendChild(ob)
            }
        }
        return docFrag
    }},
{ minWidth: 110, field: 'files',type: 'centerColumn',hide: false, headerName: 'Response Documents', sortable: false,menuTabs: ['generalMenuTab', 'columnsMenuTab'],
    cellRenderer: (params) => {
        var docFrag = document.createDocumentFragment()
        for (let index = 0; index < params.value?.length; index++) {
            let itemdoc = params.value[index]
            let img = null
            let viewclickDom = null
            var ob = document.createElement('div')
            if (itemdoc.mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || itemdoc.extension === 'docx' || itemdoc.extension === 'doc') {
                img = require('@/assets/img/word.svg')
            } else if (itemdoc.mimetype == 'application/pdf' || itemdoc.extension == 'pdf') {
                img = require('@/assets/img/adobe.svg')
            }
            else if (itemdoc.type == 'image/png' || itemdoc.type == 'image/jpeg' || itemdoc.extension == '.png' || itemdoc.extension == '.jpg' || itemdoc.extension == '.svg'  || itemdoc.extension == '.jpeg' || itemdoc.extension == '.gif') {
                img = require('@/assets/img/image.png')
            }
            else if(itemdoc.type == 'video/mp4' || itemdoc.type == 'audio/mp3')
            {
                img = require('@/assets/img/video-icon.jpg')
            }
            else if(itemdoc.mimetype == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || itemdoc.extension == 'ppt'|| itemdoc.extension == 'pptx')
            {
                img = require('@/assets/img/powerpoint.svg')
            }
            else if(itemdoc.mimetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || itemdoc.mimetype == 'text/csv' || itemdoc.extension == 'xls' | itemdoc.extension == 'xlsx' ||  itemdoc.extension == 'csv')
            {
                img = require('@/assets/img/excel.svg')
            }
            else if(itemdoc.mimetype == 'text/plain'  || itemdoc.extension == 'txt'  || itemdoc.extension == 'plain')
            {
                img = require('@/assets/img/text-format.png')
            }
            if (img !== null && img !== 'null' && img !== undefined && img !== '' && img !== ' ') {
                viewclickDom = 'viewclick_' + itemdoc.responder_document
                ob.innerHTML = `<img class="m-1" src=${img} height="30" alt="" data-action=${viewclickDom}>
        </img>`
                docFrag.appendChild(ob)
            }
        }
        return docFrag
    }},
{ minWidth: 110, field: 'recall_sites', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Site', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
{ minWidth: 120, field: 'notification_date', hide: false, headerName: 'Notification Date', sortable: true,filter: 'agDateColumnFilter',
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
    headerClass: 'dt-header',
    filterParams: {
        browserDatePicker: true,
        minValidYear: 2010,
        maxValidYear: 2030,
        // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
        filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
        defaultOption: 'equals',
        // provide comparator function
        comparator: DateComparator
    }
    , cellRenderer: (params) => {
        if (params.value == null || params.value == undefined || params.value == '' || params.value == '1900-01-01T00:00:00')
            return null
        return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
    }},
{ minWidth: 123, field: 'response_date', hide: false, headerName: 'Response Date', sortable: true,filter: 'agDateColumnFilter',
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
    headerClass: 'dt-header',
    filterParams: {
        browserDatePicker: true,
        minValidYear: 2010,
        maxValidYear: 2030,
        // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
        filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank', 'empty',],
        defaultOption: 'equals',
        // provide comparator function
        comparator: DateComparator
    }
    , cellRenderer: (params) => {
        if (params.value == null || params.value == undefined || params.value == '' || params.value == '1900-01-01T00:00:00')
            return null
        return toLocalDate(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_DATE_FORMAT })
    }},
{minWidth: 110, field: 'username',filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Respondent', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator},
{ minWidth: 110, field: 'disposal_method', filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, hide: false, headerName: 'Affected Product', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator },
{ minWidth: 100, field: 'actions',type: 'centerColumn', hide: false, headerName: 'Actions', sortable: false, cellRenderer: ErResponseActions, suppressMovable: true, suppressColumnsToolPanel: true  },
]
export const DistributionLists_Headers = [
    {field: 'name', headerName: 'Name', sortable: true,cellRenderer: distributionListsNameRenderCell,filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces }, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator},
    {field: 'recipients',type: 'rightColumn', headerName: 'Recipients', sortable: true, filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces },filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator},
    {field: 'status', type: 'centerColumn', headerName: 'Status', sortable: true, filterParams: { textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces,filterOptions: ['equals']  },filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], comparator: SortingComparator},
    {field: 'actions', type: 'centerColumn',hide: false, headerName: 'Actions', sortable: false, cellRenderer: distributionListsActionRenderCell, suppressMovable: true, suppressColumnsToolPanel: true },
]
export const product_code = [
    '',
    'DJR',
    'QLR',
    'HCC',
    'JOL',
    'LIE',
    'JID',
    'MNI',
    'LMH',
    'GET',
    'GDW',
    'OUY',
    'CBP',
    'NGE',
    'MNO',
    'LNN',
    'MNT',
    'LKN',
    'MSW',
    'HPZ',
    'NIQ',
    'PPM',
    'KEY',
    'PFX',
    'NDA',
    'NAO',
    'IRT',
    'MZO',
    'HTR',
    'KGO',
    'MCF',
    'DQT',
    'DWH',
    'DRT',
    'CCX',
    'LGC',
    'CEW',
    'KPF--',
    'DS-21',
    'EXEMP',
    'NQQ',
    'GAB',
    'HIG',
    'HGB',
    'PCD',
    'MEA',
    'LKC',
    'MMO',
    'RER',
    'PDT',
    'FMQ',
    'FJK',
    'NVQ',
    'GDF',
    'HTE',
    'LYA',
    'HAO',
    'PQA',
    'NVM',
    'JIA',
    'MCN',
    'CJW',
    'FXY',
    'LWS',
    'PLV',
    'OWN',
    'OTM',
    'JTO',
    'IDO',
    'FRI',
    'NHT',
    'INO',
    'KPG',
    'NVK',
    'DXE',
    'OZP',
    'DML',
    'JCF',
    'OJF',
    'MST',
    'MCZ',
    'OYP',
    'RFK',
    'KLS',
    'DOA',
    'EJI',
    'JZW',
    'JGQ',
    'JSG',
    'NNI',
    'INP',
    'JPA',
    'PPB',
    'ITQ',
    'FWX',
    'KWH',
    'OFY',
    'GAG',
    'GNB',
    'GGN',
    'LNY',
    'JFJ',
    'QIH',
    'JKS',
    'LOM--',
    'KOI',
    'FOX',
    'GEY',
    'NAV',
    'KLI',
    'OWF',
    'MXE',
    'LON',
    'KLC',
    'LCQ',
    'HYJ',
    'KPR',
    'NRU',
    'DFT',
    'HFS',
    'PUI',
    'FFK--',
    'CGX',
    'LOJ',
    'JSJ',
    'IKY',
    'FZS',
    'OMB',
    'PER',
    'EJK',
    'frn',
    'LLI',
    'KMW',
    'KOC',
    'LOZ',
    'PKC',
    'HFE',
    'OAY',
    'IKD',
    'IME',
    'KGB',
    'CJE',
    'OBI',
    'EZB',
    'HIF',
    'HRM',
    'HHK',
    'DXJ',
    'lkv',
    'MIJ',
    'FPB',
    'NLU',
    'OAT',
    'JPB',
    'LFL',
    'LDM',
    'KDQ',
    'MRN',
    'MJA',
    'NQK',
    'MMW',
    'FDF',
    'HEB',
    'GDY',
    'LCX',
    'JTY',
    'GFJ',
    'REN',
    'CCW',
    'FEQ',
    'KOY',
    'HQL',
    'QAI',
    'HSN',
    'NWB',
    'NZW',
    'LOK',
    'BZK',
    'NSQ',
    'dxg',
    'LOD',
    'JHR',
    'MEB',
    'PVX',
    'GAS',
    'NRP',
    'RCC',
    'FYD',
    'ITJ',
    'DPZ',
    'FTY',
    'IXW',
    'CAH',
    'LPC',
    'PND',
    'ORO',
    'GRP',
    'FEZ',
    'MQF',
    'CGN--',
    'JMG',
    'OPP',
    'D',
    'RIB',
    'EKN',
    'JYO',
    'OEZ',
    'KYJ',
    'JIT',
    'NEU',
    'PHP',
    'GZO',
    'PJI',
    'GIM',
    'LJY',
    'MKZ',
    'GMZ',
    'MQM',
    'OIE',
    'OYD',
    'BWC',
    'LFR',
    'KPQ',
    'DZi',
    'KMK',
    'HRH',
    'FMI',
    'MNL',
    'CCK',
    'KDL',
    'JOW',
    'HBB',
    'gkz',
    'NFJ',
    'NTM',
    'EAX',
    'HZT',
    '---',
    'IZX',
    'GGM',
    'IBG',
    'LXL',
    'OOM',
    'KWQ',
    'MLY',
    'LXV',
    'HCG',
    'EKM',
    'GXE',
    'HTZ',
    'PNN',
    'NGZ',
    'OCC',
    'OXO',
    'IXR',
    'HRR',
    'OWI',
    'PWC',
    'LS-99',
    'OHA',
    'MRR',
    'LIG',
    'LTD',
    'GKR',
    'ODX',
    'GAM',
    'DQY',
    'KOJ',
    'HTC',
    'HSB',
    'PEX',
    'LJT',
    'MJX',
    'FDS',
    'HPP',
    'LGX',
    'GCA',
    'JZO',
    'DAK',
    'NDJ',
    'FZP',
    'KRP',
    'LGD',
    'LAZ',
    'HKO',
    'MKJ--',
    'GTY',
    'NIP',
    'QKO',
    'JXE',
    'KMJ',
    'KXG',
    'FMP',
    'GKG',
    'DRL',
    'DXH',
    'MND',
    'MCB',
    'LQK',
    'IZI',
    'LOM',
    'KWZ',
    'FPO',
    'MMY',
    'EAZ',
    'LNX',
    'HWE',
    'CDQ',
    'FEI',
    'CKB',
    'MPU',
    'KYT',
    'MGZ',
    'DZA',
    'U28',
    'LDS',
    'GWM',
    'RHF',
    'BTO',
    'OCS',
    'BTI',
    'MCM',
    'MWS',
    'KLE',
    'NZD',
    'PAO',
    'HYF',
    'OLV',
    'KPJ',
    'LMF',
    'OZZ',
    'MFD',
    'LWG',
    'PFQ',
    'JIN',
    'IZL--',
    'PSP',
    'HXK',
    'KXN',
    'L--22',
    'PJQ',
    'HDD',
    'LGM',
    'HQD',
    'QMC',
    'DJR--',
    'HOY',
    'GQG',
    'JDK',
    'GCT',
    'FRG',
    'GBS',
    'FNJ',
    'DSJ',
    'HLI',
    'HXQ',
    'CEC',
    'JLB',
    'DXC',
    'GBX',
    'IPS',
    'MXP',
    'JAJ',
    'GAQ',
    'IMB',
    'PWE',
    'LSK',
    'LSL',
    'MAV',
    'JJQ',
    'LEZ',
    'NPV',
    'NNY',
    'QMN',
    'LJG',
    'ILS',
    'JBL',
    'JXG',
    'ONW',
    'GBL',
    'JEC',
    'GKI',
    'MTL',
    'FRZ',
    'MNR',
    'JJF',
    'IQK',
    'NRS',
    'LPQ',
    'KXK',
    'FPD',
    'PYI',
    'LZK',
    'GCW',
    'ONG',
    'LHN',
    'MJH',
    'PDQ',
    'MEF',
    'HIR',
    'NFA',
    'OEA',
    'MRG',
    'HNQ',
    'FIE',
    'HQP',
    'KGS',
    'PPR',
    'JXN',
    'HJO',
    'PKF',
    'IOR',
    'EEF',
    'MFC',
    'OOC',
    'IPR',
    'OZO',
    'HDB',
    'MYF',
    'KME',
    'NUA',
    'FTC',
    'CBK',
    'HQE',
    'JTS',
    'DXQ',
    'LDR',
    'OKE',
    'NGJ',
    'HGC',
    'MRA',
    'EKZ',
    'PIF',
    'HWW',
    'MED',
    'OGO',
    'FOS',
    'OZD',
    'HTD',
    'DWB',
    'KEW',
    'NKM',
    'QLN',
    'LNZ',
    'NVZ',
    'NXS',
    'DLZ',
    'DXO',
    'NCR',
    'HNR',
    'MQX',
    'KHQ',
    'FOZ',
    'CGN',
    'FKP',
    'REP',
    'FMK',
    'BZC',
    'CZP',
    'NDC',
    'CBZ',
    'JQC',
    'HDC',
    'CGS',
    'KRG',
    'CXT',
    'FAE',
    'GJH',
    'MDB',
    'LFQO',
    'MBV',
    'FQO',
    'CHL',
    'MBH',
    'LNW',
    'DPS',
    'LZY',
    'GCI',
    'KRY',
    'LSW',
    'DWO',
    'EMO',
    'OZT',
    'KHJ',
    'ILI',
    'LEH',
    'lZE',
    'LGW',
    'EMI',
    'MLR',
    'OLO',
    'MUJ',
    'HRY',
    'EOQ',
    'MAL',
    'KOH',
    'FKR',
    'LAO',
    'BYF',
    'MZW',
    'LKV',
    'GTJ',
    'JWX',
    'BZD',
    'OCT',
    'KOB',
    'LIZ',
    'INK',
    'BZT',
    'PFJ',
    'DTJ',
    'MPO',
    'KQZ',
    'PRI',
    'JFC',
    'HWQ',
    'MLM',
    'FMZ',
    'MUI',
    'NPZ',
    'JZJ',
    'HXS',
    'MNS',
    'GGK',
    'FZW',
    'DPW',
    'NCW',
    'OCY',
    'QBJ',
    'KIT',
    'JSH',
    'BYI',
    'ONB',
    'LJM',
    'CIJ',
    'OZN',
    'KOX',
    'IZW',
    'L',
    'GDR',
    'CIT',
    'FYE',
    'HTY',
    'MBQ',
    'REM',
    'PAV',
    'DIF',
    'XLH',
    'HFW',
    'NWU',
    'RET',
    'JRC',
    'MNQ',
    'EXY',
    'JXK',
    'CHI',
    'OBH',
    'DXT',
    'ESD',
    'CBT',
    'NUK',
    'EZL',
    'OKV',
    'rad',
    'NEZ',
    'EKR',
    'NDS',
    'OIR',
    'EZX',
    'OIB',
    'NLI',
    'IDZ',
    'MTF',
    'FKH',
    'DIH',
    'JJJ',
    'MTX',
    'GFO',
    'KNO',
    'FLL',
    'OHQ',
    'NJE',
    'HEW',
    'MKQ',
    'DMT',
    'KYI',
    'DCK',
    'MIH',
    'JBP',
    'LFG',
    'HFN',
    'MFK',
    'OAD',
    'KJJ',
    'PXC',
    'JJT',
    'JQX',
    'DZL',
    'KNX',
    'DYB',
    'DDG',
    'ZZZ',
    'MDR',
    'FZX',
    'OQG',
    'PII',
    'GZF',
    'QEP',
    'LXC',
    'MCY',
    'EMA',
    'FPK',
    'NPR',
    'GEJ',
    'NKU',
    '74',
    'KNC',
    'KKY',
    'FBQ',
    'OHH',
    'I--TI',
    'JKR',
    'OPR',
    'KTO',
    'JJH',
    'EWL',
    'IYE',
    'LRO',
    'JNL',
    'EFQ',
    'CFT',
    'JTQ',
    'FGY',
    'JNO',
    'OVN',
    'LLD',
    'EFT',
    'KFX',
    'BTE',
    'BTS',
    'MZP',
    'EZC',
    'NDP',
    'GKL',
    'OIJ',
    'LYS',
    'KRO',
    'FET',
    'ECX',
    'PMW',
    'NPD',
    'GXD',
    'LZF',
    'MXA',
    'ILG',
    'KPN',
    'BSN',
    'GJT',
    'GHQ',
    'GGP',
    'FZY',
    'EQN',
    'EHY',
    'LKB',
    'JLX',
    'HQF',
    'CDL',
    'EXX',
    'FSO',
    'LEJ',
    'FPY',
    'MJN',
    'CJO',
    'MPR',
    'KPA',
    'NKB',
    'MNH',
    'DWC',
    'HBF',
    'LWE',
    'MIP',
    'REX',
    'PGE',
    'LTW',
    'FDE',
    'LLK',
    'PBX',
    'HBO',
    'MDI',
    'DNN',
    'DYE',
    'FRN--',
    'NOC',
    'JAY',
    'BSF',
    'OGE',
    'OTD',
    'OXQ',
    'REQ',
    'LHC',
    'LSO',
    'EQJ',
    'DKC',
    'EPP',
    'GZC',
    'KAL',
    'KRL',
    'FXM',
    '000',
    'FAM',
    'OWG',
    'IWE',
    'LTK',
    'KNS',
    'CGC',
    'NQP',
    'HQN',
    'DS-22',
    'LCD',
    'JHM',
    'PSY',
    'GBA',
    'NGV',
    'KZH',
    'CDD',
    'LKA',
    'CBL',
    'CEE',
    'DWJ',
    'QLW',
    'IRQ',
    'IRP',
    'IYO',
    'PAH',
    'CDT',
    'DQE',
    'EFW',
    'NUU',
    'IMS',
    'LXB',
    'MSB',
    'KEO',
    'MGA',
    'QJQ',
    'OGD',
    'FFF',
    'FRO',
    'OGK',
    'EGG',
    'HHW',
    'FNL',
    'PDH',
    'NPN',
    'KGG',
    'MGP',
    'GEH',
    'EFA',
    'MUH',
    'OOE',
    'FII',
    'MHJ',
    'NDL',
    'EMG',
    'RCS',
    'EIH',
    'PLO',
    'LTF',
    'GJA',
    'FSA',
    'GBZ',
    'NAJ',
    'CGJ',
    'LSZ',
    'KCP',
    'JDO',
    'MRS',
    'ndr',
    'LLC',
    'GFA',
    'BTL',
    'NLF',
    'NSX',
    'EYB',
    'EBI',
    'OSF',
    'EMF',
    'FWP',
    'GJS',
    'BYG',
    'LBS',
    'NVP',
    'CIQ',
    'EFB',
    'PMN',
    'PTI',
    'cbk',
    'LFY',
    'BSR',
    'HWJ',
    'FRN',
    'LYJ',
    'LHX',
    'LQC',
    'KWI',
    'FKB',
    'KNH',
    'MLZ',
    'CIZ',
    'GDC',
    'IPM',
    'HBL',
    'NAW',
    'JJC',
    'DIC',
    'NIG',
    'KMI',
    'LOQ',
    'OJH',
    'N/A',
    'JQW',
    'JST',
    'DWQ',
    'IMG',
    'LJS',
    'EHZ',
    'OJZ',
    'MJQ',
    'OTE',
    'PHL',
    'NYQ',
    'JSP',
    'NDR',
    'NOF',
    'BXB',
    'FMT',
    'JDI',
    'NQB',
    'KQM',
    'OCK',
    'MBB',
    '79F',
    'KBW',
    'NVI',
    'BYZ',
    'CIG',
    'NED',
    'IZZ',
    'LQJ',
    'FEG',
    'JJX',
    'LRY',
    'HNL',
    'EDH',
    'MHI',
    'KAC',
    'LDJ',
    'EGS',
    'HWC',
    'LDY',
    'JFH',
    'KDP',
    'IYX',
    'NEK',
    'DCN',
    'LXM',
    'OFZ',
    'cck',
    'EJF',
    'GMD',
    'DTZ',
    'MOS',
    'LRR',
    'JHX',
    'NLH',
    'KDG',
    'RGB',
    'RBS',
    'EQH',
    'QJZ',
    'NFG',
    'LKJ',
    'LZJ',
    'MMD',
    'LCJ',
    'DEW',
    'GSO',
    'GEI',
    'JDG',
    'GKA',
    'EHJ',
    'LAF',
    'DRM',
    'FBN',
    'ETY',
    'NQE',
    'IQO',
    'PRV',
    'EZQ',
    'NQN',
    'DSB',
    'FEB',
    'OGR',
    'NTW',
    'NNW',
    'PGN',
    'ITI',
    'PCH',
    'LZP',
    'MQP',
    'MJC',
    'KTW',
    'MEQ',
    'IQA',
    'EKC',
    'MDV',
    'ETN',
    'PAM',
    'DSS',
    'PDJ',
    'ISP',
    'CBR',
    'GSK',
    'GKB',
    'FWM',
    'ECQ',
    'HXO',
    'JCX',
    'CKA',
    'HXB',
    'IYN',
    'PRH',
    'JXB',
    'LYU',
    'IMD',
    'DLJ',
    'OUO',
    'IZO',
    'GIZ',
    'NA',
    'MLM--',
    'LMA',
    'KBN',
    'CIC',
    'MIE',
    'GXI',
    'DEH',
    'LIA',
    'OYA',
    'IQZ',
    'KIJ',
    'FAF',
    'FGB',
    'PCL',
    'LYT',
    '80FMZ',
    'MRC',
    'DTK',
    'QOS',
    'PHI',
    'FCM',
    'FIR',
    'FED',
    'HTQ',
    'rce',
    'DZJ',
    'MJJ',
    'NGY',
    'BSB',
    'MAY',
    'FGO',
    'OHP',
    'IEO',
    'MTV',
    'OGZ',
    'GBB',
    'HBT',
    'CGL',
    'LPB',
    'DTI',
    'OBC',
    'KFM',
    'HXD',
    'LCI',
    'ONO',
    'LKP',
    'IMJ',
    'EAJ',
    'FRC',
    'KWL',
    'MPN',
    'NEY',
    'OPL',
    'NXT',
    'MIU',
    'OPT',
    'ONU',
    'JQW--',
    'LYZ',
    'DBA',
    'LQP',
    'KYX',
    'LLZ',
    'HMX',
    'LTI',
    'RFN',
    'DBM',
    'LIT',
    'MEH',
    'LNR',
    'IMI',
    'IPF',
    'KWS',
    'EHD',
    'PMU',
    'MKW',
    'MDU',
    'GEA',
    'JDR',
    'EZY',
    'OAP',
    'NTE',
    'FTD',
    'BTT',
    'KRD',
    'OUT',
    'HWB',
    'PCU',
    'DHB',
    'MWH',
    'LXT',
    'JAD',
    'JSO',
    'JET',
    'NSP',
    'CDS',
    'NVE',
    'GDG',
    'OGH',
    'PDZ',
    'NEO',
    'KDN',
    'PWI',
    'GHR',
    'MAF',
    'FEO',
    'PJC',
    'HXC',
    'BZQ',
    'JJM',
    'DAD',
    'PNG',
    'LIB',
    'FFA',
    'max',
    'CHH',
    'KQO',
    'KKK',
    'NDY',
    'LMB',
    'MEC',
    'JOK',
    'NWJ',
    'PRL',
    'HME',
    'NOE',
    'GZX',
    'LEA',
    'IXQ',
    'JAF',
    'QEA',
    'LJH',
    'HDF',
    'KLT',
    'FLF',
    'JIS',
    'HIS',
    'EJG',
    'FKQ',
    'DRA',
    'FTL',
    'QFG',
    'PQE',
    'PSV',
    'CAT',
    'MBL',
    'POA',
    'FAP',
    'IWF',
    'KWP',
    'FTA',
    'JTC',
    'LXH',
    'OVB',
    'LFW',
    'JDC',
    'IZQ',
    'KRZ',
    'JKC',
    'ORA',
    'JFM',
    'REG',
    'NDZ',
    'GWD',
    'LSI',
    'CAD',
    'NOP',
    'POD',
    'NVJ',
    'LTZ',
    'KGI',
    'GIR',
    'OUF',
    'GCX',
    'NJM',
    'LDQ',
    'PYC',
    'ODE',
    'GXN',
    'JLW',
    'KNT',
    'HKS',
    'EIC',
    'NIO',
    'GWN',
    'NKF',
    'INX',
    'LJW',
    'MMK',
    'HHM',
    'NTV',
    'JHW',
    'MXZ',
    'NDE',
    'DXN',
    'CEP',
    'JBF',
    'CAN',
    'MEW',
    'HQG',
    'KIH',
    'LFQ',
    'CAJ',
    'OJV',
    'KWR',
    'KKS',
    'EGJ',
    'OUH',
    'FQM',
    'MSS',
    'PEK',
    'EBA',
    'JZL',
    'PRC',
    'MWM',
    'OOD',
    'JLG',
    'KMF',
    'DHX',
    'GAE',
    'LJZ',
    'GWQ',
    'MAC',
    'JIQ',
    'HQB',
    'KNK',
    'MQR',
    'EIA',
    'FGX',
    'FMW',
    'PHC',
    'KCZ',
    'FRW',
    'FPS',
    'BSK',
    'MLA',
    'DQA',
    'NKE',
    'IZD',
    'OLR',
    'DRY',
    'GRO',
    'OHY',
    'BRX',
    'CDW',
    'BTM',
    'IWY',
    'QRY',
    'GFD',
    'KGZ',
    'FMF',
    'CBN',
    'L22',
    'FCN',
    'KCM',
    'LMD',
    'MXC',
    'MJP',
    'MPW',
    'HQX',
    'MDP',
    'NPE',
    'EBZ',
    'FBR',
    'LOR',
    'LHQ',
    'HIB',
    'HBE',
    'FFS',
    'KRA',
    'GAH',
    'JOJ',
    'OYJ',
    'NVR',
    'GBW',
    'NLM',
    'IPL',
    'ILX',
    'HKN',
    'LTJ',
    'LNO',
    'ILO',
    'NCX',
    'KZD',
    'HBC',
    'LZS',
    'LOX',
    'ISA',
    'KYF',
    'HST',
    'MJK',
    'JSD',
    'GAC',
    'OYG',
    'PAA',
    'NPT',
    'ITF',
    'EQL',
    'OQI',
    'MQJ',
    'KTT',
    'LFJ',
    'MHW',
    'LKM',
    'EZO',
    'LZA',
    'LDT',
    'OHG',
    'ODG',
    'MKJ',
    'GDS',
    'ETB',
    'MQK',
    'NBC',
    'KOE',
    'HIO',
    'CDM',
    'LOL',
    'HGX',
    'HJB',
    'GCB--',
    'PIX',
    'DTA',
    'GAO',
    'JLJ',
    'LZC',
    'cac',
    'FSH',
    'BTR',
    'CDZ',
    'FXX',
    'HXL',
    '6885',
    'JDL',
    'DRE',
    'EBF',
    'GOD',
    'KFF',
    'NGX',
    'DBF',
    'NRA',
    'GWL',
    'FWE',
    'FAD',
    'LID',
    'NEW',
    'PHU',
    'OVQ',
    'MBI',
    'NME',
    'HGL',
    'OMP',
    'BRY',
    'EDI',
    'ELR',
    'OLI',
    'GDX',
    'LNS',
    'KMM',
    'OMA',
    'OTO',
    'OIS',
    'MNV',
    'JTT',
    'JAC',
    'MDS',
    'JDP',
    'FYA',
    'OFC',
    'DYH',
    'QKP',
    'OFL',
    'NSB',
    'DYW',
    'PIU',
    'JJW',
    'LEG',
    'DTH',
    'HGM',
    'KXA',
    'LBI',
    'DRC',
    'LJE',
    'DSA',
    'JGP',
    'MXD',
    'GCC',
    'DYJ',
    'FZO',
    'CAF',
    'NQM',
    'PPN',
    'NIN',
    'MVU',
    'KHS',
    'NYD',
    'MEG',
    'HXW',
    'RFC',
    'GAP',
    'LQL',
    'INI',
    'KHY',
    'MRU',
    'MUE',
    'GTZ',
    'NJR',
    'MCX',
    'EKH',
    'OUE',
    'DRF',
    'NIM',
    'PDU',
    'BTY',
    'KYB',
    'INQ',
    'OAH',
    'KPE',
    'GXH',
    'ETA',
    'LLG',
    'ND',
    'NTO',
    'POQ',
    'NJT',
    'MQC',
    'IOL',
    'OLP',
    'DGK',
    'NNZ',
    'PKE',
    'KPO',
    'MQE',
    'DIS',
    'DWL',
    'IDP',
    'CGI',
    'KCO',
    'DHR',
    'OHR',
    'JXI',
    'QEY',
    'NWY',
    'NHC',
    'IZL',
    'LRQ',
    'LQF',
    'DEM',
    'RCR',
    'JEQ',
    'BTG',
    'RCE',
    'JFP',
    'CCT',
    'PPG',
    'LNM',
    'MOQ',
    'HEE',
    'FDT',
    'DIO',
    'LKK',
    'HET',
    'NSR',
    'BSY',
    'HYA',
    'OUP',
    'MDD',
    'PGX',
    'OSH',
    'DSP',
    'KYZ',
    'KHE',
    'CCL',
    'MSD',
    'HKY',
    'NRY',
    'JAQ',
    'LSE',
    'JBD',
    'CBI',
    'MOF',
    'EMM',
    'JJS',
    'BYL',
    'IXL',
    'OBP',
    'QLT',
    'LIF',
    'DWZ',
    'PBI',
    'OBO',
    'JCY',
    'ING',
    'IFB',
    'HQC',
    'GCB',
    'DXX',
    'LKD',
    'HBG',
    'RFZ',
    'FWH',
    'HRS',
    'MQU',
    'OCX',
    'OBK',
    'NWI',
    'OFP',
    'KLK',
    'LRJ',
    'LJB',
    'REK',
    'GYC',
    'INT',
    'MTJ',
    'RCA',
    'LDF',
    'ECO',
    'OMT',
    'MQN',
    'CCN',
    'EKS',
    'JWY',
    'KPS--',
    'JLH',
    'OJJ',
    'DTM',
    'LKO',
    'NWR',
    'BRZ',
    'EBG',
    'QBD',
    'MKT',
    'EJL',
    'NFK',
    'GAD',
    'DTW',
    'PNO',
    'LHO',
    'GFC',
    'CCQ',
    'JAX',
    'LBK',
    'HGY',
    'NPJ',
    'GCP',
    'DWE',
    'MCH',
    'DWS',
    'LXS',
    'FRL',
    'QKR',
    'JHY',
    'IOQ',
    'NZT',
    'PEW',
    'FNG',
    'JDY',
    'NEG',
    'BWF',
    'OMN',
    'ETD',
    'NSU',
    'NFN',
    'FMS',
    'CAX',
    'PQD',
    'mkj',
    'LRM',
    'BTC',
    'MWI',
    'FEM',
    'FBK',
    'HJN',
    'LHJ',
    ':',
    'CFF',
    'MVH',
    'NJL',
    'LQE',
    'JDX',
    'HCH',
    'MFT',
    'PGW',
    'ISH',
    'QRF',
    'LCM',
    'IKX',
    'FHQ',
    'KSD',
    'QEI',
    'MBD',
    'KSB',
    'OWB',
    'IPY',
    'PLF',
    'MSX',
    'QKY',
    'FHA',
    'NVY',
    'KGC',
    'FNY',
    'HJM',
    'NTR',
    'HGD',
    'JEB',
    'DBI',
    'OSN',
    'OUW',
    'OSR',
    'LIQ',
    'DYF',
    'PZG',
    'FFX',
    'JAI',
    'JJG',
    'FFL',
    'BSO',
    'CEK',
    'FHN',
    'OES',
    'FLG',
    'GWG',
    'NIE',
    'FDG',
    'NQX',
    'NCP',
    'IFP',
    'GNW',
    'FRK',
    'PHV',
    'KMA',
    'NXB',
    'MSE',
    'IXY',
    'LZN',
    'MBP',
    'HTW',
    'KIM',
    'JDQ',
    'PQZ',
    'BYD',
    'GKF',
    'JAK',
    'MMX',
    'DQX',
    'FMJ',
    'OJU',
    'LWH',
    'JSC',
    'PBZ',
    'OSI',
    'LDP',
    'PLS',
    'PIO',
    'BGK',
    'ELC',
    'MWA',
    'LNH',
    'OYC',
    'MQS',
    'NKC',
    'KRI',
    'KXJ',
    'LTT',
    'FTR',
    'ELZ',
    'MVR',
    'LPL',
    'RDZ',
    'FTM',
    'MVV',
    'BZR',
    'QFJ',
    'MNJ',
    'DSQ',
    'ISL',
    'GKP',
    'BYJ',
    'KPY',
    'OIP',
    'PGY',
    'HLF',
    'OOY',
    'EPT',
    'KRR',
    'JFS',
    'LPS',
    'HPS',
    'KZI',
    'PEN',
    'NFF',
    'LRZ',
    'DRO',
    'OGS',
    'JJY',
    'GEN',
    'KZF',
    'GYB',
    'HJL',
    'OWD',
    'MLD',
    'NBW',
    'OCL',
    'FSP',
    'GCK',
    'JIY',
    'MVC',
    'CDP',
    'MAX',
    'MYM',
    'FQH',
    'MSV',
    'EID',
    'JJE',
    'FPL',
    'MQH',
    'HGP',
    'GBN',
    'NQL',
    'GKZ',
    'MMS',
    'JWH',
    'LWJ',
    'KNQ',
    'MID',
    'EKB',
    'KHG',
    'PLP',
    'JOY',
    'LLT',
    'DHC',
    'MDZ',
    'NIU',
    'GMW',
    'DRX',
    'DWF',
    'GBQ',
    'BWN',
    'MGQ',
    'DER',
    'MQB',
    'CKH',
    'jjy',
    'MGO',
    'OYT',
    'JBS',
    'NUJ',
    'LGI',
    'CFL',
    'MOD',
    'FQZ',
    'DDR',
    'GYK',
    'MGM',
    'ION',
    'KKH',
    'FHI',
    'OII',
    'PTJ',
    'NJK',
    'HNM',
    'DHN',
    'GCD',
    'BXH',
    'GNX',
    'PZP',
    'POE',
    'ITG',
    'HPT',
    'HMY',
    'CIW',
    'LYC',
    'LRK',
    'LCP',
    'GQX',
    'HEM',
    'K--DD',
    'GDZ',
    'NAA',
    'KNG',
    'KYG',
    'HTJ',
    'GKE',
    'CAZ',
    'KMN',
    'GXQ',
    'MMP',
    'GBK',
    'CPK',
    'LLB',
    'PGI',
    'KXH',
    'FCW',
    'HDW',
    'PFF',
    'MRZ',
    'JDB',
    'JZH',
    'MGU',
    'KXE',
    'JOH',
    'JTW',
    'LRC',
    'LMO',
    'DKA',
    'BTA',
    'OHW',
    'ONN',
    'EBW',
    'KWY',
    'IDY',
    'CBH',
    'NHB',
    'LQM',
    'JTN',
    'OVD',
    'KPS',
    'NXQ',
    'MYT',
    'PZE',
    'DSK',
    'MPN--',
    'FST',
    'MAO',
    'NKQ',
    'QOF',
    'LAN',
    'GAW',
    'NAB',
    'FDI',
    'FWF',
    'FSY',
    'MNB',
    'OWL',
    'CFP',
    'MUU',
    'ILQ',
    'ODP',
    'GZI',
    'PIC',
    'LHI',
    'MOT',
    'LRF',
    'GAZ',
    'FQA',
    'OTL',
    'MOZ',
    'MXK',
    'NQD',
    'FFK',
    'LRN',
    'OGX',
    'OSP',
    'HDA',
    'HXX',
    'KIR',
    'NWO',
    'JCC',
    'DGW',
    'FNM',
    'OAE',
    'JKD',
    '90LMA',
    'MRW',
    'OAB',
    'ODI',
    'CKD',
    'HJK',
    'JQP',
    'HAM',
    'KNY',
    'CHN',
    'LHL',
    'GAB--',
    'EZN',
    'GAA',
    'PAW',
    'HXP',
    'OTN',
    'NXX',
    'LZB',
    'LYL',
    'KRQ',
    'ISD',
    'ETP',
    'PAD',
    'NOM',
    'RBY',
    'GXP',
    'LLH',
    'KNW',
    'ORZ',
    'LKQ',
    'IRO',
    'DXY',
    'GXZ',
    'HJI',
    'NAE',
    'ITY',
    'ITW',
    'EBC',
    'LRS',
    'OFF',
    'NBH',
    'HXJ',
    'FLS',
    'PQP',
    'FZM',
    'JNB',
    'MGB',
    'FKG',
    '-JTC',
    'NFO',
    'NZA',
    'OAS',
    'MDM',
    'DTE',
    'JSK',
    'JOX',
    'DEG',
    'HQZ',
    'FJL',
    'EWO',
    'OHS',
    'FIN',
    'HDQ',
    'LWP',
    'ELW',
    'HWT',
    'IKT',
    'OFT',
    'GXL',
    'HLM',
    'DXF',
    'IKK',
    'OMI',
    'MWJ',
    'IWB',
    'NSA',
    'OAR',
    'MUD',
    'KDI',
    'OHV',
    'JFT',
    'KBJ',
    'OMO',
    'HAX',
    'NPF',
    'FCP',
    'F',
    'NHX',
    'JOR',
    'FCK',
    'JCW',
    'KKO',
    'FPP',
    'LKF',
    'GAT',
    'OFQ',
    'FRF',
    'FSG',
    'LKH',
    'JNM',
    'GKJ',
    'PLL',
    'JPK',
    'MHX',
    'PFV',
    'POL',
    'JHS',
    'BXN',
    '-IYE',
    'FSM',
    'QBH',
    'PBQ',
    'KGN',
    'OAW',
    'OHD',
    'IYB',
    'RCG',
    'FKX',
    'DFC',
    'NCD',
    'MGX',
    'PKL',
    'FWN',
    'IMP',
    'HYB',
    'GKT',
    'GNC',
    'ONA',
    'MPK',
    'JSI',
    'GHM',
    'LHI--',
    'QES',
    'GAX',
    'QEW',
    'EXQ',
    'MAA',
    'FFT',
    'LZU',
    'IXA',
    'OGJ',
    'MLN',
    'MBF',
    'JJN',
    'LPD',
    'NDF',
    'KLB',
    'MAQ',
    'OCJ',
    'IQI',
    'BYW',
    'LPN',
    'REA',
    'NBE',
    'DXS',
    'EJJ',
    'MVN',
    'FKX--',
    'NON',
    'CJY',
    'GES',
    'CGR',
    'NHA',
    'OZE',
    'PFS',
    'FPA',
    'IZO--',
    'DJZ',
    'DSF',
    'RGW',
    'LPP',
    'NKX',
    'HRX',
    'PNY',
    'POS',
    'HMT',
    'JAA',
    'GZJ',
    'LFK',
    'JHJ',
    'KHP',
    'DIC--',
    'OTT',
    'KLA',
    'GQO',
    'MUA',
    'CDN',
    'LIP',
    'IWJ',
    'EZD',
    'LOE',
    'NQO',
    'JOZ',
    'EXE',
    'GER',
    'MOL',
    'NAY',
    'PWL',
    'MLT',
    'LWK',
    'IWX',
    'EXB',
    'LOS',
    'PQF',
    'QMT',
    'PWY',
    '--',
    'JSY',
    'OUJ',
    'OUG',
    'OIY',
    'JOQ',
    'DFH',
    'NQZ',
    'NPM',
    'LYW',
    'KIS',
    'JHI',
    'OYS',
    'LSM',
    'OEX',
    'HKI',
    'PMT',
    'IKZ',
    'OFD',
    'PYX',
    'PQQ',
    'REO',
    'KAK',
    'MVM',
    'EOX',
    'OEU',
    'GQH',
    'GHH',
    'PEV',
    'M',
    'OUB',
    'HXZ',
    '74DWF',
    'MYL',
    'FCL',
    'FJS',
    'CIN',
    'FLD',
    'HTS',
    'EJB',
    'gbx',
    'HTT',
    'OYE',
    'GAR',
    'NWD',
    'LAS',
    'IBM',
    'FZZ',
    'JEA',
    'FCG',
    'OWO',
    'HCF',
    'HGS',
    'MDT',
    'LBH',
    'CHP',
    'KGT',
    'PEP',
    'LMC',
    'NGG',
    'CIX',
    'PVY',
    'INW',
    'GIF',
    'HSX',
    'KDC',
    'ERL',
    'PRR',
    'PGQ',
    'LJC',
    'JEY',
    'KKX',
    'INM',
    'KAM',
    'LYR',
    'MIX',
    'GSG',
    'HXI',
    'FMG',
    'LZO',
    'CAL',
    'NTI',
    'KFK',
    'PSZ',
    'JXM',
    'OOK',
    'DSY',
    'JPD',
    'ESW',
    'JJI',
    'HGI',
    'DXG',
    'MSQ',
    'KZJ',
    'FXG',
    'IBJ',
    'DZI',
    'ISM',
    'NAC',
    'MDK',
    'NLQ',
    'HZJ',
    'QJR',
    'LBR',
    'MJG',
    'IZF',
    'DGC',
    'RDW',
    'MMH',
    'FSE',
    'MTD',
    'JTR',
    'DAP',
    'OEI',
    'LRP',
    'GZT',
    'LFH',
    'FGS',
    'JGS',
    'GDJ',
    'GCQ',
    'FER',
    'PDR',
    'BYX',
    'NPQ',
    'OJG',
    'JFY',
    'KQT',
    'KGY',
    'OOG',
    'HMP',
    'NES',
    'OAF',
    'NCF',
    'FGE',
    'NUC',
    'ITX',
    'MHY',
    'JTZ',
    'EXS',
    'GCY',
    'HNO',
    'HSD',
    'HWD',
    'PTM',
    'KSG',
    'NYI',
    'HNN',
    'CAE',
    'IMK',
    'J',
    'EXF',
    'BZE',
    'CGZ',
    'CGA',
    'KWT',
    'HWA',
    'HCI',
    'LXG',
    'NFB',
    'EYN',
    'MQV',
    'DKJ',
    'OTU',
    'LGB',
    'FJP',
    'ECI',
    'IDT',
    'HOZ',
    'LPO',
    'IWZ',
    'O-FY',
    'DSI',
    'BZO',
    'MPB',
    'KDM',
    'HMR',
    'FAS',
    'MLS',
    'PRB',
    'DXZ',
    'H--NN',
    'JDS',
    'OGG',
    'JRB',
    'JKA',
    'GCJ',
    'NHN',
    'LDH',
    'PKD',
    'OYK',
    'PVZ',
    'CFJ',
    'IZJ',
    'N/a',
    'GGT',
    'GHC',
    'LPH',
    'CZW',
    'DTN',
    'KWA',
    'LYB',
    'OQU',
    'CIB',
    'DCF',
    'GWO',
    'CBF',
    'NML',
    'OVE',
    'JIF',
    'LSR',
    'HIH',
    'JPI',
    'JFL',
    'PXP',
    'OQO',
    'GWF',
    'LTK--',
    'OLW',
    'MKI',
    'FPX',
    'GXY',
    'NZE',
    'PEJ',
    'PMC',
    'MZA',
    'ITH',
    'DTY',
    'HAW',
    'FLE',
    'MQL',
    'JRM',
    'OFG',
    'GBR',
    'KKX--',
    'GDM',
    'DSZ',
    'ODW',
    'NPL',
    'DRG',
    'MHZ',
    'hpz',
    'MAE',
    'MOV',
    'JDH',
    'GZB',
    'DBL',
    'LZG',
    'GMJ',
    'MGR',
    'NHG',
    'DIP',
    'EBL',
    'BZA',
    'JSS',
    'NIK',
    'JGE',
    'PQK',
    'IMF',
    'GSM',
    'NTG',
    'LRD',
    'FIK',
    'NXA',
    'BTQ',
    'EZW',
    'OJN',
    'CEM',
    'GAL',
    'OKC',
    'JWM',
    'NQI',
    'FMH',
    'DRS',
    'GBO',
    'MYA',
    'MNM',
    'BSP',
    'OEV',
    'GGW',
    'GAA41',
    'HBA',
    'HAB',
    'MCW',
    'MUQ',
    'LPG',
    'MRV',
    'INN',
    'MMI',
    'CDO',
    'EME',
    'QDS',
    'JGJ',
    'KPR--',
    'LLM',
    'EMX',
    'NZG',
    'KOO',
    'KXF',
    'ISY',
    'KIF',
    'LFX',
    'GFG',
    'KXT',
    'FQL',
    'BYH',
    'KOD',
    'KMC',
    'LYQ',
    'GBM',
    'KPT',
    'LER',
    'NLW',
    'JMT',
    'LWR',
    'JOP',
    'PMP',
    'JMM',
    'KWD',
    'NGT',
    'KST',
    'KTN',
    'ISW',
    'DHA',
    'DTB',
    'KCN',
    'CAI',
    'LFD',
    'NOU',
    'MTG',
    'KHO',
    'FWZ',
    'LBB',
    'KIC',
    'LLL',
    'NHL',
    'JCB',
    'HHI',
    'GDT',
    'BYE',
    'JCT',
    'DZD',
    'NHJ',
    'HXY',
    'NPK',
    'DKJ--',
    'OJK',
    'DRQ',
    'ILH',
    'DKZ',
    'JCM',
    'GOY',
    'BZN',
    'JPF',
    'IXI',
    'LGZ',
    'muj',
    'CFR',
    'PRD',
    'KDJ',
    'CKG',
    'MOM',
    'KDH',
    'LIX',
    'FDX',
    'EOB',
    'HDX',
    'U--19',
    'LZD',
    'HSZ',
    'OLC',
    'KQJ',
    'EIE',
    'QAN',
    'GEX',
    'GXR',
    'FEN',
    'NWV',
    'FCX',
    'DWA',
    'KDD',
    'DKB',
    'QRA',
    'EFN',
    'IXJ',
    'OWQ',
    'NWX',
    'KRB',
    'PEH',
    'IKN',
    'LBJ',
    'KYW',
    'HWR',
    'DTS',
    'NEP--',
    'IYH',
    'OOI',
    'MPH',
    'QHE',
    'MPL',
    'DZC',
    'NMF',
    'JSM',
    'LOB',
    'OCZ',
    'OGL',
    'DQK',
    'GBT',
    'HTO',
    'KFZ',
    'GKH',
    'PXO',
    'JDW',
    'GSN',
    'DYG',
    'OJR',
    'LJX',
    'MXM',
    'LFM',
    'FRJ',
    'KZA',
    'PGH',
    'LNQ',
    'FCH',
    'NSF',
    'HWX',
    'HTN',
    'NQA',
    'OEQ',
    'GIO',
    'HBX',
    'JD-01',
    'PJB',
    'GTQ',
    'GZL',
    'PHX',
    'KQG',
    'FBO',
    'FCS',
    'CEN',
    'KOA',
    'GWJ',
    'CEC--',
    'OIG',
    'BSJ',
    'PHO',
    'FHW',
    'DZE',
    'DTL',
    'NAD',
    'REU',
    'GNP',
    'BBT',
    'DQO',
    'CGF',
    'LRG',
    'FTN',
    'CAC',
    'HFM',
    'ILY',
    'HWN',
    'GEL',
    'MRD',
    'MRM',
    'EAP',
    '-KPS',
    'JLA',
    'LZH',
    'DRN',
    'LCH',
    'DDC',
    'LPA',
    'FWB',
    'LQR',
    'NOT',
    'NOX',
    'DTR',
    'LYY',
    'DOF',
    'NDN',
    'PNJ',
    'MAI',
    'ILK',
    'GFF',
    'G-IO',
    'MUZ',
    'JRJ',
    'HCA',
    'OFM',
    'CCH',
    'RHP',
    'GNA',
    'JLS',
    'QHJ',
    'OFA',
    'JCA',
    'LEI',
    'NDW',
    'OBJ',
    'NOV',
    'KQC',
    'HQY',
    'MLV',
    'KZP',
    'JIL',
    'ECN',
    'PSG',
    'LRW',
    'JMO',
    'FAJ',
    'FNK',
    'KPF',
    'LGQ',
    'JIX',
    '91',
    'LOF',
    'HAP',
    'QAU',
    'KSR',
    'KDT',
    'QLG',
    'JOS',
    'GWE',
    'LCO',
    'DOP',
    'EYZ',
    'LWQ',
    'KZE',
    'FFO',
    'MMZ',
    'MYN',
    'DWM',
    'NEP',
    'KCT',
    'NEC',
    'HTX',
    'PMX',
    'MJO',
    'LFZ',
    'PNH',
    'LPM',
    'EHK',
    'FFZ',
    'FAQ',
    'DQR',
    'OHM',
    'NYU',
    'KTI',
    'LQQ',
    'PEZ',
    'EBR',
    'DPT',
    'MQG',
    'HFX',
    'FGM',
    'JZG',
    'KCY',
    'FAT',
    'JZQ',
    'CEO',
    'OPU',
    'OQY',
    'DZN',
    'PAX',
    'JIH',
    'KJA',
    'KRK',
    'OFX',
    'DJG',
    'LRT',
    'PXI',
    'KGX',
    'NKG',
    'BSZ',
    'MSH',
    'LFB',
    'LDD',
    'MVK',
    'FZT',
    'KOG',
    'KNF',
    'PFT',
    'JDZ',
    '74GBK',
    'NDG',
    'FIP',
    'GDI',
    'DTQ',
    'EAM',
    'MXG',
    'DAQ',
    'GDO',
    'QDI',
    'FJI',
    'NZJ',
    'OTR',
    'NDH',
    'KNN',
    'BYY',
    'IZH',
    'GSY',
    'HNJ',
    'QLU',
    'CAW',
    'CFN',
    'JQA',
    'LIO',
    'MOI',
    'FME',
]
export const openFdaRecalls_Headers = [
    {
        field: 'recalling_firm',
        headerName: 'Recalling Firm',
        hide: false,
        lockVisible: true,
        filter: 'agTextColumnFilter',
        toolPanelClass: 'tp-hideaction',
        sortable: true,
    },
    {
        field: 'recall_status',
        headerName: 'Recall Status',
        suppressMenu: false,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['','Open, Classified', 'Completed', 'Terminated'],
            excelMode: 'windows',
        },
        sortable: true,
    },
    {
        field: 'classification',
        headerName: 'Recall Classification',
        suppressMenu: false,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['','Class I', 'Class II', 'Class III'],
            excelMode: 'windows',
        },
        sortable: true,
    },
    {
        field: 'recall_type',
        headerName: 'Recall Type',
        suppressMenu: false,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['','Removal', 'Correction'],
            excelMode: 'windows',
        },
        sortable: true,
    },
    {
        field: 'event_date_initiated',
        headerName: 'Event Date Initiated',
        suppressMenu: false,
        filter: 'agDateColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressAndOrCondition: true,
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            filterOptions: [
                'equals',
                'notEqual',
                'lessThan',
                'greaterThan',
                'inRange',
                'blank',
                'notBlank',
                'empty',
            ],
            defaultOption: 'equals',
            comparator: DateComparator,
        },
        sort: 'desc',
        cellRenderer: (params) => {
            if (
                params.value == null ||
          params.value == undefined ||
          params.value == ''
            )
                return null

            return moment(params.value).format('MM/DD/YYYY')
        },
    },
    {
        field: 'event_date_posted',
        headerName: 'Event Date Posted',
        suppressMenu: false,
        hide: true,
        filter: 'agDateColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressAndOrCondition: true,
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            filterOptions: [
                'equals',
                'notEqual',
                'lessThan',
                'greaterThan',
                'inRange',
                'blank',
                'notBlank',
                'empty',
            ],
            defaultOption: 'equals',
            comparator: DateComparator,
        },
        cellRenderer: (params) => {
            if (
                params.value == null ||
          params.value == undefined ||
          params.value == ''
            )
                return null

            return moment(params.value).format('MM/DD/YYYY')
        },
    },
    {
        field: 'event_date_terminated',
        headerName: 'Event Date Terminated',
        suppressMenu: false,
        filter: 'agDateColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressAndOrCondition: true,
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            filterOptions: [
                'equals',
                'notEqual',
                'lessThan',
                'greaterThan',
                'inRange',
                'blank',
                'notBlank',
                'empty',
            ],
            defaultOption: 'equals',
            comparator: DateComparator,
        },
        cellRenderer: (params) => {
            if (
                params.value == null ||
          params.value == undefined ||
          params.value == ''
            )
                return null

            return moment(params.value).format('MM/DD/YYYY')
        },
    },
    {
        field: 'product_code',
        headerName: 'Product Code',
        suppressMenu: false,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: product_code,
            excelMode: 'windows',
        },
    },
    {
        field: 'product_description',
        headerName: 'Product Description',
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'event_date_created',
        headerName: 'Event Date Created',
        hide: true,
        suppressMenu: false,
        filter: 'agDateColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressAndOrCondition: true,
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            filterOptions: [
                'equals',
                'notEqual',
                'lessThan',
                'greaterThan',
                'inRange',
                'blank',
                'notBlank',
                'empty',
            ],
            defaultOption: 'equals',
            comparator: DateComparator,
        },
        cellRenderer: (params) => {
            if (
                params.value == null ||
          params.value == undefined ||
          params.value == ''
            )
                return null

            return moment(params.value).format('MM/DD/YYYY')
        },
    },
    {
        field: 'res_event_number',
        headerName: 'Res Event Number',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'address_1',
        headerName: 'Address 1',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains'],
        },
    },
    {
        field: 'address_2',
        headerName: 'Address 2',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'city',
        headerName: 'City',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'state',
        headerName: 'State',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'postal_code',
        headerName: 'Postal Code',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'additional_info_contact',
        headerName: 'Additional Info Contact',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'root_cause_description',
        headerName: 'Root Cause Description',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'reason_for_recall',
        headerName: 'Reason For Recall',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'action',
        headerName: 'Action',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'product_quantity',
        headerName: 'Product Quantity',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'distribution_pattern',
        headerName: 'Distribution Pattern',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'product_res_number',
        headerName: 'Product Res Number',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'firm_fei_number',
        headerName: 'Firm FEI Number',
        hide: true,
        suppressMenu: false,
        enablePivot: true,
        pivot: true,
        flex: null,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'cfres_id',
        headerName: 'Cfres Id',
        hide: true,
        suppressMenu: false,
        enablePivot: true,
        pivot: true,
        flex: null,
        filter: 'agTextColumnFilter',
    },

    {
        field: 'k_numbers',
        headerName: 'K Numbers',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'recall_link',
        headerName: 'Recall Link',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
        cellRenderer: (params) => {
            return `<a href="${params.value}" target="_blank">${params.value}</a>`
        },
    },
    {
        field: 'openfda.device_class',
        headerName: 'openFDA - Device Class',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.device_name',
        headerName: 'openFDA - Device Name',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.fei_number',
        headerName: 'openFDA - FEI Number',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.k_number',
        headerName: 'openFDA - K Number',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.medical_specialty_description',
        headerName: 'openFDA - Medical Specialty Description',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.registration_number',
        headerName: 'openFDA - Registration Number',
        suppressMenu: false,
        menuTabs: ['generalMenuTab', 'columnsMenuTab', 'filterMenuTab'],
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.regulation_number',
        headerName: 'openFDA - Regulation Number',
        suppressMenu: false,
        menuTabs: ['generalMenuTab', 'columnsMenuTab', 'filterMenuTab'],
        hide: true,
        filter: 'agTextColumnFilter',
    },
]
export const openFdaDrugRecalls_Headers = [
    {
        field: 'openfda.manufacturer_name',
        headerName: 'openFDA - Manufacturer Name',
        suppressMenu: false,
        hide: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'recalling_firm',
        headerName: 'Recalling Firm',
        hide: false,
        lockVisible: true,
        filter: 'agTextColumnFilter',
        toolPanelClass: 'tp-hideaction',
        sortable: true,
    },
    {
        field: 'status',
        headerName: 'Status',
        suppressMenu: false,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['', 'Ongoing', 'Completed', 'Terminated'],
            excelMode: 'windows',
        },
        sortable: true,
    },
    {
        field: 'classification',
        headerName: 'Classification',
        suppressMenu: false,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['','Class I', 'Class II', 'Class III'],
            excelMode: 'windows',
        },
        sortable: true,
    },
    {
        field: 'openfda.brand_name',
        headerName: 'openFDA - Brand Name',
        suppressMenu: false,
        hide: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.generic_name',
        headerName: 'openFDA - Generic Name',
        suppressMenu: false,
        hide: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'recall_number',
        headerName: 'Recall Number',
        hide: false,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'recall_initiation_date',
        headerName: 'Recall Initiation Date',
        suppressMenu: false,
        filter: 'agDateColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressAndOrCondition: true,
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            filterOptions: [
                'equals',
                'notEqual',
                'lessThan',
                'greaterThan',
                'inRange',
                'blank',
                'notBlank',
                'empty',
            ],
            defaultOption: 'equals',
            comparator: DateComparator,
        },
        sort: 'desc',
        cellRenderer: (params) => {
            if (
                params.value == null ||
          params.value == undefined ||
          params.value == ''
            )
                return null

            return moment(params.value).format('MM/DD/YYYY')
        },
    },
    {
        field: 'center_classification_date',
        headerName: 'Center Classification Date',
        suppressMenu: false,
        hide: true,
        filter: 'agDateColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressAndOrCondition: true,
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            filterOptions: [
                'equals',
                'notEqual',
                'lessThan',
                'greaterThan',
                'inRange',
                'blank',
                'notBlank',
                'empty',
            ],
            defaultOption: 'equals',
            comparator: DateComparator,
        },
        cellRenderer: (params) => {
            if (
                params.value == null ||
          params.value == undefined ||
          params.value == ''
            )
                return null

            return moment(params.value).format('MM/DD/YYYY')
        },
    },
    {
        field: 'address_1',
        headerName: 'Address 1',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains'],
        },
    },
    {
        field: 'address_2',
        headerName: 'Address 2',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'city',
        headerName: 'City',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'country',
        headerName: 'Country',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'distribution_pattern',
        headerName: 'Distribution Pattern',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'event_id',
        headerName: 'Event Id',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'initial_firm_notification',
        headerName: 'Initial Firm Notification',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'inserted_date',
        headerName: 'Inserted Date',
        suppressMenu: false,
        hide: true,
        filter: 'agDateColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressAndOrCondition: true,
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            filterOptions: [
                'equals',
                'notEqual',
                'lessThan',
                'greaterThan',
                'inRange',
                'blank',
                'notBlank',
                'empty',
            ],
            defaultOption: 'equals',
            comparator: DateComparator,
        },
        cellRenderer: (params) => {
            if (
                params.value == null ||
          params.value == undefined ||
          params.value == ''
            )
                return null

            return moment(params.value).format('MM/DD/YYYY')
        },
    },
    {
        field: 'openfda.application_number',
        headerName: 'openFDA - Application Number',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.is_original_packager',
        headerName: 'openFDA - Original Packager',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.nui',
        headerName: 'openFDA - Nui',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.original_packager_product_ndc',
        headerName: 'openFDA - Original Packager Product Ndc',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.package_ndc',
        headerName: 'openFDA - Package Ndc',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.pharm_class_cs',
        headerName: 'openFDA - Pharm Class Cs',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.pharm_class_epc',
        headerName: 'openFDA - Pharm Class Epc',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.pharm_class_moa',
        headerName: 'openFDA - Pharm Class Moa',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.pharm_class_pe',
        headerName: 'openFDA - Pharm Class Pe',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.product_ndc',
        headerName: 'openFDA - Product Ndc',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.product_type',
        headerName: 'openFDA - Product Type',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.route',
        headerName: 'openFDA - Route',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.rxcui',
        headerName: 'openFDA - Rxcui',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.spl_id',
        headerName: 'openFDA - Spl Id',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.spl_set_id',
        headerName: 'openFDA - Spl Set Id',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.substance_name',
        headerName: 'openFDA - Substance Name',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.unii',
        headerName: 'openFDA - Unii',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'openfda.upc',
        headerName: 'openFDA - Upc',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'postal_code',
        headerName: 'Postal Code',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'product_description',
        headerName: 'Product Description',
        suppressMenu: false,
        hide: true,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'product_quantity',
        headerName: 'Product Quantity',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'product_type',
        headerName: 'Product Type',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'reason_for_recall',
        headerName: 'Reason For Recall',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'report_date',
        headerName: 'Report Date',
        suppressMenu: false,
        hide: true,
        filter: 'agDateColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressAndOrCondition: true,
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            filterOptions: [
                'equals',
                'notEqual',
                'lessThan',
                'greaterThan',
                'inRange',
                'blank',
                'notBlank',
                'empty',
            ],
            defaultOption: 'equals',
            comparator: DateComparator,
        },
        cellRenderer: (params) => {
            if (
                params.value == null ||
          params.value == undefined ||
          params.value == ''
            )
                return null

            return moment(params.value).format('MM/DD/YYYY')
        },
    },
    {
        field: 'state',
        headerName: 'State',
        hide: true,
        suppressMenu: false,
        filter: 'agTextColumnFilter',
    },
    {
        field: 'termination_date',
        headerName: 'Termination Date',
        suppressMenu: false,
        hide: true,
        filter: 'agDateColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressAndOrCondition: true,
            browserDatePicker: true,
            minValidYear: 2010,
            maxValidYear: 2030,
            filterOptions: [
                'equals',
                'notEqual',
                'lessThan',
                'greaterThan',
                'inRange',
                'blank',
                'notBlank',
                'empty',
            ],
            defaultOption: 'equals',
            comparator: DateComparator,
        },
        cellRenderer: (params) => {
            if (
                params.value == null ||
          params.value == undefined ||
          params.value == ''
            )
                return null

            return moment(params.value).format('MM/DD/YYYY')
        },
    },
    {
        field: 'voluntary_mandated',
        headerName: 'Voluntary Mandated',
        suppressMenu: false,
        hide: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['', 'FDA Mandated', 'N/A', 'Voluntary: Firm initiated'],
            excelMode: 'windows',
        },
        sortable: true,
    }
]