var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationProvider',{attrs:{"rules":_vm.rules,"name":_vm.validatorName,"disabled":_vm.disabled || _vm.disabledValidate},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{ref:"wrapper",staticClass:"form-group-float position-relative",style:({marginBottom: _vm.hideDetails ? '0px': '5px'})},[(_vm.label != '')?_c('div',{ref:"lbl",staticClass:"label position-absolute",on:{"click":_vm.clickLabel}},[_c('label',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.label))])]):_vm._e(),_c('fieldset',{staticClass:"fs",class:[{
         disabled: _vm.disabled,
         'is-valid': !_vm.disabled ? (_vm.disabledValidate ? null : _vm._getValidationState(validationContext)) : null,
         'is-invalid': !_vm.disabled ? (_vm.disabledValidate ? null : _vm._getValidationState(validationContext) === false) : null
       },
       ((_vm.disabled || _vm.disabledValidate) ? '' : _vm.getClassIndicate(validationContext))
      ]},[_c('legend',{staticClass:"lg",style:({ width: _vm.lblWidth })},[_vm._v(" ")]),_c('b-form-input',{ref:"theInput",staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.placeholder,"state":!_vm.disabled ? (_vm.disabledValidate ? null : _vm._getValidationState(validationContext)) : null},on:{"input":_vm.onChange,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.onArrowDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.onArrowUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnter.apply(null, arguments)}],"focus":_vm.fieldFocus,"blur":_vm.fieldBlur},model:{value:(_vm.selectedvalue),callback:function ($$v) {_vm.selectedvalue=$$v},expression:"selectedvalue"}})],1),_c('b-list-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"group"},[[(_vm.isLoading)?_c('b-list-group-item',{staticClass:"loading"},[_vm._v(" Loading results... ")]):_vm._l((_vm.results),function(result,i){return _c('b-list-group-item',{key:i+'-option',class:{ 'is-active': i === _vm.arrowCounter },on:{"click":function($event){return _vm.setResult(result)}}},[_vm._v(" "+_vm._s(result)+" "),_vm._t("item")],2)})]],2),(!_vm.hideDetails)?_c('b-form-invalid-feedback',{staticClass:"error"},[_vm._v(_vm._s(validationContext.errors[0]))]):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }