var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationProvider',{attrs:{"rules":_vm.rules,"name":_vm.validatorName,"disabled":_vm.disabled || _vm.disabledValidate},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{ref:"wrapper",staticClass:"form-group-float position-relative",style:({marginBottom: _vm.hideDetails ? '0px': '5px'})},[(_vm.label != '')?_c('div',{ref:"lbl",staticClass:"label position-absolute",on:{"click":_vm.clickLabel}},[_c('label',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.label))])]):_vm._e(),_c('fieldset',{staticClass:"fs",class:[{
         disabled: _vm.disabled,
         'is-valid': !_vm.disabled ? (_vm.disabledValidate ? null : _vm._getValidationState(validationContext)) : null,
         'is-invalid': !_vm.disabled ? (_vm.disabledValidate ? null : _vm._getValidationState(validationContext) === false) : null
       },
       ((_vm.disabled || _vm.disabledValidate) ? '' : _vm.getClassIndicate(validationContext))
      ]},[_c('legend',{staticClass:"lg",style:({ width: _vm.lblWidth })},[_vm._v(" ")]),_c('b-form-input',{ref:"theInput",staticClass:"form-control",attrs:{"type":_vm.type,"id":_vm.id,"placeholder":_vm.placeholder,"state":!_vm.disabled ? (_vm.disabledValidate ? null : _vm._getValidationState(validationContext)) : null,"disabled":_vm.disabled,"autocomplete":_vm.autocomplete,"maxlength":_vm.maxlength},on:{"focus":_vm.fieldFocus,"blur":_vm.fieldBlur,"update":_vm.onUpdate,"change":_vm.change,"keydown":_vm.btKeyUp,"keyup":_vm.keyup},model:{value:(_vm._value),callback:function ($$v) {_vm._value=$$v},expression:"_value"}})],1),(!_vm.hideDetails)?_c('b-form-invalid-feedback',{staticClass:"error"},[_vm._v(_vm._s(validationContext.errors[0]))]):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }