var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationProvider',{attrs:{"rules":_vm.rules,"name":_vm.validatorName,"disabled":_vm.disabled || _vm.disabledValidate},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('div',{ref:"select"},[_c('div',{ref:"wrapper",staticClass:"from-group-float position-relative",style:({marginBottom: '5px'})},[_c('div',{staticClass:"position-relative"},[_c('fieldset',{staticClass:"fs",class:[{
             disabled: _vm.disabled,
             'is-valid': !_vm.disabled ? (_vm.disabledValidate ? null : _vm._getValidationState(validationContext)) : null,
             'is-invalid': !_vm.disabled ? (_vm.disabledValidate ? null : _vm._getValidationState(validationContext) === false) : null
           },
           ((_vm.disabled || _vm.disabledValidate) ? '' : _vm.getClassIndicate(validationContext))
          ]},[_c('legend',{ref:"theLegend",staticClass:"legend",style:({width: _vm.lblWidth})},[_vm._v(" ")]),_c('b-form-textarea',{ref:"textArea",staticClass:"textarea",attrs:{"rows":_vm.rows,"max-rows":_vm.maxRows,"placeholder":_vm.placeholder,"state":!_vm.disabled ? (_vm.disabledValidate ? null : _vm._getValidationState(validationContext)) : null},on:{"focus":_vm.hoverLabel,"blur":_vm.leaveLabel},model:{value:(_vm._value),callback:function ($$v) {_vm._value=$$v},expression:"_value"}})],1),(_vm.placeholder == '')?_c('span',{ref:"theLabel",staticClass:"the-label position-absolute",on:{"click":_vm.labelClick}},[_c('label',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.label))])]):_vm._e()])]),(!_vm.hideDetails)?_c('b-form-invalid-feedback',{staticClass:"error"},[_vm._v(_vm._s(validationContext.errors[0]))]):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }